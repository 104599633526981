import { css } from 'styled-components';

const zIndices = [
  'stickyNav',
  'navSubItems',
  'donateBar',
  'modal',
  'takeover',
  'tabOnlyA11y',
  'mobileNav',
].reduce((acc, name, index) => ({ ...acc, [name]: index + 10 }), {});

const theme = {
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    darkGrey: '#333333',
    grey: '#8A8A8A',
    lightGrey: 'rgba(0,0,0,0.4)',
    hairline: 'rgba(0,0,0,0.2)',
    darkPurple: '#3E2C70',
    mediumPurple: '#6B49DC',
    lavender: '#A78BF2',
    emerald: '#139675',
    mint: '#2EFFF0',
    lightMint: '#95FFF7',
    coral: '#FF9B7B',
    backgroundPurple: '#F9F7FE',
    lightCoral: '#FFD0C1',
  },

  zIndices: {
    ...zIndices,
  },

  font: 'Fellix, Poppins, system-ui, sans-serif',

  fz: {
    xs: '14px',
    sm: '16px',
    md: '18px',
    lg: '22px',
    xl: '24px',
  },

  transition: 'all 0.2s ease-in',

  bp: {
    mobileS: `min-width: 330px`,
    mobileM: `min-width: 400px`,
    mobileL: `min-width: 480px`,
    tabletS: `min-width: 600px`,
    tabletL: `min-width: 768px`,
    desktopXS: `min-width: 900px`,
    desktopS: `min-width: 1080px`,
    desktopM: `min-width: 1200px`,
    desktopL: `min-width: 1400px`,
  },

  layout: {
    gutter: '32',
    contentMaxWidth: '974',
    globalMaxWidth: '1076',
    wideMaxWidth: '1200',
    extraWideMaxWidth: '1320',
  },

  button: {
    radius: '72.6px',
  },

  breakpoints: {
    mobileSmall: '320px',
    mobileMedium: '375px',
    mobileLarge: '410px',
    ngpFormRow: '600px',
    tablet: '768px',
    desktopSmall: '1024px',
    desktopMedium: '1440px',
    desktopLarge: '2560px',
  },

  mixins: {
    flexCenter: (direction = 'row') => css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: ${direction};
    `,
    flexBetween: (direction = 'row') => css`
      display: flex;
      justify-content: space-between;
      flex-direction: ${direction};
    `,
    listStyleNone: () => css`
      list-style: none;
      margin: 0;
      padding: 0;
    `,
    oneColumnText: () => css`
      max-width: 60%;
    `,
    twoColumns: (gap = '20px') => css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: ${gap};
    `,
    smallPurpleText: () => css`
      color: ${({ theme }) => theme.colors.darkPurple};
      font-size: ${({ theme }) => theme.fz.sm};
    `,
    allCapsText: (fz = '') => css`
      color: ${({ theme }) => theme.colors.darkPurple};
      font-size: ${({ theme }) => fz || theme.fz.xs};
      font-weight: 700;
      letter-spacing: 2px;
      text-transform: uppercase;
    `,
  },
};

export default theme;
