import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { WHITE, BACKGROUND_PURPLE } from '@utils';

const MOBILE_SIZE = 40;
const SIZE = 45;

const StyledColorPairButton = styled.button`
  padding: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50%;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.lightGrey};
  }

  &.active {
    border-color: ${({ theme }) => theme.colors.grey};
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .color-button__inner {
    display: flex;
    border: 1px solid transparent;
    border-radius: 50%;

    .color-button__left,
    .color-button__right {
      width: ${MOBILE_SIZE / 2}px;
      height: ${MOBILE_SIZE}px;

      @media (${({ theme }) => theme.bp.mobileL}) {
        width: ${SIZE / 2}px;
        height: ${SIZE}px;
      }
    }

    .color-button__left {
      border-bottom-left-radius: ${MOBILE_SIZE}px;
      border-top-left-radius: ${MOBILE_SIZE}px;

      @media (${({ theme }) => theme.bp.mobileL}) {
        border-bottom-left-radius: ${SIZE}px;
        border-top-left-radius: ${SIZE}px;
      }
    }
    .color-button__right {
      border-bottom-right-radius: ${MOBILE_SIZE}px;
      border-top-right-radius: ${MOBILE_SIZE}px;

      @media (${({ theme }) => theme.bp.mobileL}) {
        border-bottom-right-radius: ${SIZE}px;
        border-top-right-radius: ${SIZE}px;
      }
    }
  }
`;

const ColorPairButton = ({ colorPair, clickHandler, active, disabled }) => (
  <StyledColorPairButton
    onClick={clickHandler}
    className={`color-button ${active ? 'active' : ''}`}
    disabled={disabled}
  >
    <div
      className={`color-button__inner ${
        colorPair[1].hex === WHITE.hex || colorPair[1].hex === BACKGROUND_PURPLE.hex
          ? 'hairline-border'
          : ''
      }`}>
      <div className="color-button__left" style={{ backgroundColor: colorPair[0].hex }}>
        <div className="visually-hidden">{colorPair[0].name}</div>
      </div>
      <div className="color-button__right" style={{ backgroundColor: colorPair[1].hex }}>
        <div className="visually-hidden">{colorPair[1].name}</div>
      </div>
    </div>
  </StyledColorPairButton>
);

ColorPairButton.propTypes = {
  colorPair: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      hex: PropTypes.string.isRequired,
    }),
  ),
  clickHandler: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ColorPairButton;
