import React from 'react';
import LinkButton from './linkButton.styles';
import Link from './link';

/* eslint react/prop-types: 0 */

const PrimaryLinkButton = props => {
  const {
    children, href, size = 'medium', buttonTheme = 'coral', buttonHoverTheme = 'coral',
    textTheme = 'darkPurple', textHoverTheme = 'mint',
  } = props;

  return (
    <LinkButton
      buttonTheme={buttonTheme}
      buttonHoverTheme={buttonHoverTheme}
      textTheme={textTheme}
      textHoverTheme={textHoverTheme}
      size={size}
    >
      <Link href={href}>
        {children}
      </Link>
    </LinkButton>
  );
};

export default PrimaryLinkButton;
