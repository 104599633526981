import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const StyledSupporterGallery = styled.section`
  .gallery__intro {
    @media (${({ theme }) => theme.bp.tabletL}) {
      ${({ theme }) => theme.mixins.oneColumnText()};
    }
  }

  .gallery__inner {
    @media (${({ theme }) => theme.bp.mobileL}) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }

    @media (${({ theme }) => theme.bp.tabletL}) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .gallery__lockup {
    margin-bottom: 30px;
  }

  .gallery__lockup-img {
    margin-bottom: 15px;
  }
`;

const SupporterGallery = () => {
  const data = useStaticQuery(graphql`
    query {
      cad: file(relativePath: { eq: "supporter-gallery/cad.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      knit: file(relativePath: { eq: "supporter-gallery/knit.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      illustration: file(relativePath: { eq: "supporter-gallery/illustration.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledSupporterGallery id="gallery">
      <h2>Supporter Gallery</h2>

      <p className="gallery__intro">
        We want to see what you make! Send your design system creations to{' '}
        <a href="mailto:info@michelleforboston.com">info@michelleforboston.com</a> and we'll post it
        here for <a href="https://www.instagram.com/explore/tags/wutrain/">#WuTrain</a> inspiration.
      </p>

      <div className="gallery__inner">
        <div className="gallery__lockup">
          <div className="gallery__lockup-img">
            <Img fluid={data.cad.childImageSharp.fluid} />
          </div>
          <a
            href="https://drive.google.com/drive/folders/18cmJiGKX8bmiJ9VEcd_2f-PsNsKy8BHY?usp=sharing"
            className="download-link">
            Download CAD Files
          </a>
        </div>

        <div className="gallery__lockup">
          <div className="gallery__lockup-img">
            <Img fluid={data.knit.childImageSharp.fluid} />
          </div>
          <a
            href="https://drive.google.com/drive/folders/1kdUB_8txU1gXD5jitDlIHQqR_BFu9WSV?usp=sharing"
            className="download-link">
            Download Colorwork Chart
          </a>
        </div>

        <div className="gallery__lockup">
          <div className="gallery__lockup-img">
            <Img fluid={data.illustration.childImageSharp.fluid} />
          </div>
          <a
            href="https://assets.ctfassets.net/1hf11j69ure4/1IlDBls6FEyvSHuSFgmY9X/3fc02b0d7998dc6d68449f67adbb9ccb/Wu_Illustrations.zip"
            className="download-link">
            Download our Illustration Pack
          </a>
        </div>
      </div>
    </StyledSupporterGallery>
  );
};

export default SupporterGallery;
