import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

let interval;
let timeout;
let enteredTimeout;

const transitions = ({ duration }) => ({
  'fade-default': {
    transition: `opacity ${duration}ms ease-in`,
    opacity: 0,
  },

  'fade-entering': {
    opacity: 0,
  },

  'fade-entered': {
    opacity: 1,
  },

  'fade-exiting': {
    opacity: 0,
  },

  'fade-exited': {
    opacity: 0,
  },
});

const StyledHeaderText = styled.h1`
  color: ${({ theme }) => theme.colors.mediumPurple};
  font-size: 40px;
  line-height: 1;

  @media (${({ theme }) => theme.bp.mobileL}) {
    font-size: 50px;
    max-width: 420px;
  }

  @media (${({ theme }) => theme.bp.tabletL}) {
    font-size: 60px;
    max-width: 500px;
  }

  @media (${({ theme }) => theme.bp.desktopS}) {
    font-size: 80px;
    max-width: 750px;
  }

  .keyword {
    color: ${({ theme }) => theme.colors.emerald};
  }
`;

const RotatingHeadline = () => {
  const KEYWORDS = ['human', 'vibrant', 'kind', 'joyful', 'strong'];

  const [loopStarted, setLoopStarted] = useState(false);

  const indexRef = useRef(0);
  const [entered, setEntered] = useState(false);
  const text = KEYWORDS[indexRef.current] || '';

  const time = 4000;
  const transitionTime = 250;
  const startDelay = 3000;

  const styles = transitions({ duration: transitionTime });

  const next = () => {
    indexRef.current = KEYWORDS[indexRef.current + 1] ? indexRef.current + 1 : 0;
    setEntered(true);
    enteredTimeout = setTimeout(() => setEntered(false), time - transitionTime);
  };

  useEffect(() => {
    timeout = setTimeout(() => {
      setLoopStarted(true);
      next();
      interval = setInterval(next, time);
    }, startDelay);

    return () => clearTimeout(timeout) && clearInterval(interval) && clearTimeout(enteredTimeout);
  }, []);

  return (
    <StyledHeaderText>
      Building a{' '}
      {!loopStarted ? (
        <span className="keyword">strong</span>
      ) : (
        <Transition in={entered} timeout={transitionTime}>
          {state => (
            <span
              key={indexRef}
              className="keyword"
              style={{
                ...styles[`fade-default`],
                ...styles[`fade-${state}`],
              }}>
              {text}
            </span>
          )}
        </Transition>
      )}{' '}
      vision for Boston.
    </StyledHeaderText>
  );
};

export default RotatingHeadline;
