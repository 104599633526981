import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '@images/social/facebook.svg';
import TwitterIcon from '@images/social/twitter.svg';
import InstagramIcon from '@images/social/instagram.svg';
import UpstatementLogo from '@images/upstatement.svg';

const StyledFooter = styled.footer`
  .footer__contact {
    margin-bottom: 50px;

    @media (${({ theme }) => theme.bp.tabletL}) {
      display: flex;
      align-items: flex-end;
    }
  }

  .footer__description {
    margin: 0 0 40px 0;

    @media (${({ theme }) => theme.bp.tabletL}) {
      ${({ theme }) => theme.mixins.oneColumnText()};
      margin: 0 40px 0 0;
    }
  }

  .footer__social {
    ${({ theme }) => theme.mixins.listStyleNone()};
    display: flex;

    li {
      margin-right: 10px;
    }

    a {
      ${({ theme }) => theme.mixins.flexCenter()};
      width: 40px;
      height: 40px;
      padding: 5px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.mediumPurple};
      color: ${({ theme }) => theme.colors.white};

      &:hover,
      &:focus {
        background-color: ${({ theme }) => theme.colors.coral};
        color: ${({ theme }) => theme.colors.darkPurple};
      }
    }
  }

  .footer__credit {
    position: relative;
    font-size: ${({ theme }) => theme.fz.md};

    .ups-logo {
      margin-left: 5px;
      position: relative;
      top: 1px;

      @media (${({ theme }) => theme.bp.desktopS}) {
        top: 0;
      }

      a {
        &:hover,
        &:focus {
          svg path {
            transition: ${({ theme }) => theme.transition};
            fill: ${({ theme }) => theme.colors.mediumPurple};
          }
        }
      }

      svg {
        width: 100px;

        @media (${({ theme }) => theme.bp.mobileL}) {
          width: 130px;
        }
      }
    }
  }
`;

const Footer = () => (
  <StyledFooter>
    <h2>Get In Touch</h2>

    <div className="footer__contact">
      <p className="footer__description">
        For more information about how to get involved, please visit{' '}
        <a href="http://www.michelleforboston.com/">michelleforboston.com</a>. If you have questions
        about these guidelines, please email{' '}
        <a href="mailto:info@michelleforboston.com">info@michelleforboston.com</a>.
      </p>

      <ul className="footer__social">
        <li>
          <a href="https://www.facebook.com/michelleforboston" className="plain-link">
            <FacebookIcon />
            <div className="visually-hidden">Facebook</div>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/wutrain" className="plain-link">
            <TwitterIcon />
            <div className="visually-hidden">Twitter</div>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/wutrain/" className="plain-link">
            <InstagramIcon />
            <div className="visually-hidden">Instagram</div>
          </a>
        </li>
      </ul>
    </div>

    <div className="footer__credit">
      The Michelle Wu for Boston campaign identity and grassroots toolkit was created by Michelle's
      friends at{' '}
      <span className="ups-logo">
        <a href="https://www.upstatement.com/" className="plain-link">
          <UpstatementLogo />
          <span className="visually-hidden">Upstatement</span>
        </a>
      </span>
    </div>
  </StyledFooter>
);

export default Footer;
