import React, { useEffect } from 'react';
import ClipboardJS from 'clipboard';
import styled from 'styled-components';
import { BRAND_COLORS, COLOR_PAIRINGS, WHITE } from '@utils';
import { PaletteColor } from '@components';

const StyledColors = styled.section`
  ul {
    ${({ theme }) => theme.mixins.listStyleNone()};
  }

  .color__description {
    margin-bottom: 20px;

    @media (${({ theme }) => theme.bp.tabletL}) {
      ${({ theme }) => theme.mixins.oneColumnText()};
      margin-bottom: 0;
    }
  }
`;

const StyledPairings = styled.div`
  margin-top: 50px;

  ul.color__pairings {
    ${({ theme }) => theme.mixins.flexBetween()};

    @media (${({ theme }) => theme.bp.desktopS}) {
      justify-content: flex-start;
    }

    li.color__pair {
      display: flex;
      flex-grow: 1;

      @media (${({ theme }) => theme.bp.desktopS}) {
        flex-grow: initial;
      }

      &:not(:last-child) {
        margin-right: 15px;

        @media (${({ theme }) => theme.bp.mobileM}) {
          margin-right: 20px;
        }

        @media (${({ theme }) => theme.bp.tabletS}) {
          margin-right: 30px;
        }
      }
    }

    .color {
      position: relative;
      border-radius: 30px;
      width: 50%;

      /* to maintain aspect ratio */
      &:before {
        content: '';
        display: block;
        padding-top: 170%;
      }

      @media (${({ theme }) => theme.bp.desktopS}) {
        width: 35px;
        height: 60px;
      }
    }
  }

  .color__accessibility {
    margin-top: 30px;
    color: ${({ theme }) => theme.colors.grey};
    font-size: ${({ theme }) => theme.fz.md};
  }
`;

const StyledColorPalette = styled.div`
  width: 100%;
  margin-top: 50px;

  @media (${({ theme }) => theme.bp.tabletL}) {
    ${({ theme }) => theme.mixins.flexBetween()};
  }

  .colors__left {
    flex-grow: 1;

    > div {
      height: 50%;
    }
  }

  .colors__right {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (${({ theme }) => theme.bp.tabletL}) {
      width: 50%;
    }
  }
`;

const Colors = () => {
  const copy_text = 'Copy Hex';

  /**
   * Initialize Clipboard JS
   * @see https://clipboardjs.com/
   */
  const initClipboard = () => {
    const clipboard = new ClipboardJS('.js-clipboard');

    clipboard.on('success', e => {
      const copySpan = e.trigger.querySelector('.copy-text');
      copySpan.textContent = 'Copied!';
      e.clearSelection();
      setTimeout(() => (copySpan.textContent = copy_text), 2000);
    });
  };

  useEffect(() => {
    initClipboard();
  }, []);

  return (
    <StyledColors id="color">
      <div className="color__description">
        <h2>Our Color Palette</h2>
        <p>
          The color system is rooted in Michelle's signature dark purple, and to that we have added
          a suite of vibrant complementary tones that allow for a full range of expression. Below
          are some of our favorite pairings.
        </p>
      </div>

      <StyledPairings>
        <ul className="color__pairings">
          {COLOR_PAIRINGS
            ? Object.values(COLOR_PAIRINGS).map(([first, second], i) => (
              <li key={i} className="color__pair">
                <div
                  className={`color ${first.hex === WHITE.hex ? 'hairline-border' : ''}`}
                  style={{ backgroundColor: first ? first.hex : '' }}>
                  <span className="visually-hidden">{first.name}</span>
                </div>
                <div
                  className={`color ${second.hex === WHITE.hex ? 'hairline-border' : ''}`}
                  style={{ backgroundColor: second ? second.hex : '' }}>
                  <span className="visually-hidden">{second.name}</span>
                </div>
              </li>
            ))
            : null}
        </ul>

        <p className="color__accessibility">
          Key pairings pass{' '}
          <a href="https://www.w3.org/WAI/standards-guidelines/wcag/" className="plain-link">
            accessibility guidelines
          </a>
          .
        </p>
      </StyledPairings>

      <StyledColorPalette>
        <div className="colors__left">
          <PaletteColor color={BRAND_COLORS.darkPurple} />
          <PaletteColor color={BRAND_COLORS.mediumPurple} />
        </div>

        <div className="colors__right">
          <PaletteColor color={BRAND_COLORS.lavender} isSquare={true} />
          <PaletteColor color={BRAND_COLORS.coral} isSquare={true} />
          <PaletteColor color={BRAND_COLORS.emerald} isSquare={true} />
          <PaletteColor color={BRAND_COLORS.mint} isSquare={true} />
        </div>
      </StyledColorPalette>
    </StyledColors>
  );
};

export default Colors;
