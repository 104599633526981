import React from 'react';
import useCheckExternalLink from '../../utils/checkExternalLink';

/* eslint react/prop-types: 0 */

const GatsbyLink = props => {
  const { href, children, ...rest } = props;
  const isExternal = useCheckExternalLink(href);

  if (!href) {
    return null;
  }
  if (isExternal) {
    return (
      <a href={href} {...rest}>{children}</a>
    );
  }

  return (
    <a href={href} {...rest}>{children}</a>
  );
};

export default GatsbyLink;
