import React from 'react';
import PropTypes from 'prop-types';
import { BRAND_COLORS } from '@utils';
import styled from 'styled-components';

const StyledPaletteColor = styled.div`
  .color__button {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0;
    position: relative;
    color: ${({ theme }) => theme.colors.white};
    text-align: left;
    user-select: text;

    &:hover,
    &:focus {
      .copy-text {
        opacity: 1;
      }
    }

    &.isSquare {
      @media (${({ theme }) => theme.bp.tabletL}) {
        &:before {
          content: '';
          display: block;
          padding-top: 100%;
        }
      }
    }

    &.dark-text {
      color: ${({ theme }) => theme.colors.darkPurple};
    }

    .copy-text {
      opacity: 0;
      transition: ${({ theme }) => theme.transition};
      font-weight: 700;
    }
  }

  .color__button-text {
    ${({ theme }) => theme.mixins.flexBetween('column')};
    padding: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;

    @media (${({ theme }) => theme.bp.mobileL}) {
      font-size: ${({ theme }) => theme.fz.xs};
    }

    @media (${({ theme }) => theme.bp.tabletL}) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 15px;
      font-size: ${({ theme }) => theme.fz.xs};
    }

    @media (${({ theme }) => theme.bp.desktopS}) {
      padding: 20px;
      font-size: ${({ theme }) => theme.fz.sm};
    }

    .color__info {
      margin-bottom: 10px;
    }

    .color__name {
      margin: 0 0 10px;
      font-size: inherit;
      font-weight: 700;
    }

    ul.color__values {
      li {
        margin-bottom: 5px;
        white-space: nowrap;

        .label {
          display: inline-block;
          margin-right: 5px;
          font-weight: 700;

          @media (${({ theme }) => theme.bp.desktopS}) {
            margin-right: 10px;
          }
        }

        .value {
          letter-spacing: 0.5px;

          @media (${({ theme }) => theme.bp.desktopS}) {
            letter-spacing: 1px;
          }
        }
      }
    }
  }
`;

const PaletteColor = ({ color, isSquare }) => (
  <StyledPaletteColor>
    <button
      data-clipboard-text={color.hex}
      className={`color__button js-clipboard ${isSquare ? 'isSquare' : ''} ${
        color.hex === BRAND_COLORS.coral.hex || color.hex === BRAND_COLORS.mint.hex
          ? 'dark-text'
          : ''
      }`}
      style={{ backgroundColor: color.hex }}>
      <div className="color__button-text">
        <div className="color__info">
          <p className="color__name">{color.name}</p>

          <ul className="color__values">
            <li>
              <span className="label">CMYK:</span>
              <span className="value">{color.cmyk}</span>
            </li>
            <li>
              <span className="label">RGB:</span>
              <span className="value">{color.rgb}</span>
            </li>
            <li>
              <span className="label">HEX:</span>
              <span className="value">{color.hex}</span>
            </li>
          </ul>
        </div>

        <span className="copy-text">Copy Hex</span>
      </div>
    </button>
  </StyledPaletteColor>
);

PaletteColor.propTypes = {
  color: PropTypes.shape({
    name: PropTypes.string,
    hex: PropTypes.string,
    rgb: PropTypes.string,
    cmyk: PropTypes.string,
  }),
  isSquare: PropTypes.bool,
};

export default PaletteColor;
