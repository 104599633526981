import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import CoalitionActivists from '@images/logos/drawer/coalition/activists.svg';
import CoalitionParents from '@images/logos/drawer/coalition/parents.svg';
import CoalitionStudents from '@images/logos/drawer/coalition/students.svg';

const StyledLogosDrawer = styled.div`
  .drawer__intro {
    max-width: 500px;
    margin-bottom: 50px;
  }

  .drawer__grid {
    .drawer__grid-item-image {
      &.filled {
        background-color: ${({ theme }) => theme.colors.darkPurple};
      }

      &.horizontal {
        > div {
          padding: 0 10%;

          @media (${({ theme }) => theme.bp.desktopXS}) {
            padding: 0 20%;
          }
        }
      }

      &.icons {
        > div {
          padding: 0 10%;
        }
      }
    }
  }

  .coalitions__grid {
    ${({ theme }) => theme.mixins.flexCenter()};
    justify-content: space-around;
    margin-bottom: 30px;
    padding: 30px;
    border: 1px solid ${({ theme }) => theme.colors.darkPurple};

    @media (${({ theme }) => theme.bp.desktopS}) {
      padding: 50px;
    }

    .grid-item {
      margin: 0 10px;
    }
  }

  .coalitions__text {
    ${({ theme }) => theme.mixins.oneColumnText()};
    color: ${({ theme }) => theme.colors.darkPurple};
    font-size: ${({ theme }) => theme.fz.sm};
  }
`;

const LogosDrawer = () => {
  const data = useStaticQuery(graphql`
    query {
      reversed: file(relativePath: { eq: "logos/drawer/reversed.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lavender: file(relativePath: { eq: "logos/drawer/lavender.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      horizontals: file(relativePath: { eq: "logos/drawer/horizontals.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      icons: file(relativePath: { eq: "logos/drawer/icons.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledLogosDrawer>
      <p className="drawer__intro">
        Below are a collection of alternative logos to use when the above assets are not suitable.
      </p>

      <div className="drawer__grid">
        <div className="drawer__grid-item">
          <div className="drawer__grid-item-lockup">
            <div className="drawer__grid-item-image filled">
              <div>
                <Img fluid={data.reversed.childImageSharp.fluid} />
              </div>
            </div>
            <p>This reversed logo should only be used against a dark purple background.</p>
          </div>
        </div>

        <div className="drawer__grid-item">
          <div className="drawer__grid-item-lockup">
            <div className="drawer__grid-item-image">
              <div>
                <Img fluid={data.lavender.childImageSharp.fluid} />
              </div>
            </div>
            <p>
              “For mayor” may be translated as needed. Please{' '}
              <a href="mailto:info@michelleforboston.com">email us</a> for logo translation
              requests.
            </p>
          </div>
        </div>

        <div className="drawer__grid-item">
          <div className="drawer__grid-item-lockup">
            <div className="drawer__grid-item-image horizontal">
              <div>
                <Img fluid={data.horizontals.childImageSharp.fluid} />
              </div>
            </div>
            <p>The horizontal design may be used on applications like bumper stickers.</p>
          </div>
        </div>

        <div className="drawer__grid-item">
          <div className="drawer__grid-item-lockup">
            <div className="drawer__grid-item-image icons">
              <div>
                <Img fluid={data.icons.childImageSharp.fluid} />
              </div>
            </div>
            <p>
              Here is our icon shown in our favorite colorways. For social avatars, you may need to omit
              the textured container shape.
            </p>
          </div>
        </div>
      </div>

      <div className="coalitions">
        <div className="coalitions__grid">
          <div className="grid-item">
            <CoalitionActivists />
          </div>
          <div className="grid-item">
            <CoalitionParents />
          </div>
          <div className="grid-item">
            <CoalitionStudents />
          </div>
        </div>
        <p className="coalitions__text">
          Supporter groups can use their own signature color outside our core palette. Please{' '}
          <a href="mailto:info@michelleforboston.com">email us</a> if you have a supporter group
          you’re interested in creating a logo for.
        </p>
      </div>
    </StyledLogosDrawer>
  );
};

export default LogosDrawer;
