import React from 'react';
import styled from 'styled-components';
import { Drawer, TypographyDrawer } from '@components';
import Heading1 from '@images/typography/heading1.svg';
import Heading2 from '@images/typography/heading2.svg';
import Heading3 from '@images/typography/heading3.svg';

const StyledType = styled.section`
  .type__inner {
    display: flex;
    flex-direction: column;
  }

  .type__title {
    @media (${({ theme }) => theme.bp.tabletL}) {
      order: 2;
    }
  }

  .type__description {
    margin-bottom: 10px;

    @media (${({ theme }) => theme.bp.tabletL}) {
      ${({ theme }) => theme.mixins.twoColumns('40px')};
      order: 3;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .type__headings {
    margin: 20px 0;

    @media (${({ theme }) => theme.bp.tabletL}) {
      order: 1;
      margin: 40px 0;
    }

    .heading {
      width: 100%;
      margin-bottom: 3px;

      @media (${({ theme }) => theme.bp.mobileL}) {
        margin-bottom: 10px;
      }

      @media (${({ theme }) => theme.bp.tabletL}) {
        margin-bottom: 20px;
      }

      &.one {
        margin-bottom: 10px;

        @media (${({ theme }) => theme.bp.mobileL}) {
          margin-bottom: 20px;
        }

        @media (${({ theme }) => theme.bp.tabletL}) {
          margin-bottom: 35px;
        }
      }
    }
  }
`;

const SecondParagraph = () => (
  <p>
    We use the bold and regular weights of Fellix, most often in sentence case. Our fallback is{' '}
    <a href="https://fonts.google.com/specimen/Poppins">Poppins</a> if a free typeface is needed to
    substitute.
  </p>
);

const Type = () => (
  <StyledType id="typography">
    <div className="type__inner">
      <h2 className="type__title">Typography</h2>

      <div className="type__description">
        <p>
          <a href="https://displaay.net/typeface/fellix/">Fellix</a> is an optimistic geometric
          voice for the campaign, referencing the proportions of printed handwriting. It was
          designed by Displaay Type in 2017 and inspired by Futura. Purchasing a license is required
          to use this typeface.
        </p>

        <div className="desktop-only">
          <SecondParagraph />
        </div>
      </div>

      <div className="type__headings">
        <div className="heading one">
          <Heading1 />
          <span className="visually-hidden">Join Michelle in imagining a better Boston</span>
        </div>
        <div className="heading two">
          <Heading2 />
          <span className="visually-hidden">Michelle for Mayor</span>
        </div>
        <div className="heading three">
          <Heading3 />
          <span className="visually-hidden">Fighting for equity and opportunity</span>
        </div>
      </div>

      <div className="mobile-only">
        <SecondParagraph />
      </div>
    </div>

    <Drawer drawerId="typography-drawer" contentId="typography-content">
      <TypographyDrawer />
    </Drawer>
  </StyledType>
);

export default Type;
