import React from 'react';
import styled from 'styled-components';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import CurvyArrow from '@images/header/curvy-arrow.svg';

const StyledTOC = styled.div`
  position: relative;

  @media (${({ theme }) => theme.bp.mobileS}) {
    padding-left: 30px;
  }

  @media (${({ theme }) => theme.bp.desktopS}) {
    padding-left: 0;
  }

  .toc__arrow {
    display: none;

    @media (${({ theme }) => theme.bp.mobileS}) {
      display: block;
      position: absolute;
      width: 40px;
      left: -10px;
      top: -10px;
    }

    @media (${({ theme }) => theme.bp.desktopS}) {
      width: 60px;
      left: -50px;
      top: -20px;
    }
  }

  .toc__heading {
    ${({ theme }) => theme.mixins.allCapsText()};
    margin-bottom: 10px;
  }

  .toc__list {
    ${({ theme }) => theme.mixins.listStyleNone()};
    column-count: 2;
    width: max-content;

    li {
      margin: 0 0 5px 0;

      @media (${({ theme }) => theme.bp.mobileS}) {
        margin: 0 5px 5px 0;
      }

      @media (${({ theme }) => theme.bp.tabletL}) {
        margin: 0 10px 5px 0;
      }
    }
  }
`;

const TOC = () => (
  <StyledTOC>
    <div className="toc__arrow">
      <CurvyArrow />
    </div>

    <p className="toc__heading">Jump To</p>

    <ul className="toc__list">
      <li>
        <AnchorLink to="#graphics-generator" className="plain-link">
          Graphics Generator
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to="#logos" className="plain-link">
          Campaign Logos
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to="#gallery" className="plain-link">
          Supporter Gallery
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to="#color" className="plain-link">
          Color Palette
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to="#typography" className="plain-link">
          Typography
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to="#photography" className="plain-link">
          Photography
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to="#imagery" className="plain-link">
          Imagery
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to="#pattern" className="plain-link">
          Pattern
        </AnchorLink>
      </li>
      <li>
        <AnchorLink to="#textures" className="plain-link">
          Textures
        </AnchorLink>
      </li>
    </ul>
  </StyledTOC>
);

export default TOC;
