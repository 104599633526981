import { theme } from '@styles';

export const BRAND_COLORS = {
  darkPurple: {
    name: 'Dark Purple',
    hex: theme.colors.darkPurple,
    rgb: '62, 44, 112',
    cmyk: '45, 61, 0, 56',
  },
  mediumPurple: {
    name: 'Medium Purple',
    hex: theme.colors.mediumPurple,
    rgb: '107, 73, 220',
    cmyk: '51, 67, 0, 14',
  },
  lavender: {
    name: 'Lavender',
    hex: theme.colors.lavender,
    rgb: '167, 139, 242',
    cmyk: '31, 43, 0, 5',
  },
  emerald: {
    name: 'Emerald',
    hex: theme.colors.emerald,
    rgb: '19, 150, 117',
    cmyk: '87, 0, 22, 41',
  },
  mint: {
    name: 'Mint',
    hex: theme.colors.mint,
    rgb: '46, 255, 240',
    cmyk: '82, 0, 6, 4',
  },
  coral: {
    name: 'Coral',
    hex: theme.colors.coral,
    rgb: '255, 155, 123',
    cmyk: '0, 39, 52, 0',
  },
};

export const WHITE = {
  name: 'White',
  hex: theme.colors.white,
};

export const BACKGROUND_PURPLE = {
  name: 'Background Purple',
  hex: theme.colors.backgroundPurple,
};

export const COLOR_PAIRINGS = {
  darkPurple_mint: [BRAND_COLORS.darkPurple, BRAND_COLORS.mint],
  darkPurple_Lavender: [BRAND_COLORS.darkPurple, BRAND_COLORS.lavender],
  darkPurple_coral: [BRAND_COLORS.darkPurple, BRAND_COLORS.coral],
  mediumPurple_white: [BRAND_COLORS.mediumPurple, WHITE],
  darkPurple_white: [BRAND_COLORS.darkPurple, WHITE],
  emerald_white: [BRAND_COLORS.emerald, WHITE],
  emerald_mint: [BRAND_COLORS.emerald, BRAND_COLORS.mint],
};

export const LOGOS = {
  primary: {
    name: 'Primary Logo',
    path: 'primary',
  },
  chinese: {
    name: 'Chinese Logo',
    path: 'chinese',
  },
  horizontal: {
    name: 'Horizontal Logo',
    path: 'horizontal',
  },
  pattern: {
    name: 'Pattern Logo',
    path: 'pattern',
  },
};

export const LOGO_PALETTES = {
  lavender_on_dark_purple: [BRAND_COLORS.lavender, BRAND_COLORS.darkPurple],
  coral_on_white: [BRAND_COLORS.coral, WHITE],
  mint_on_white: [BRAND_COLORS.mint, WHITE],
  lavender_on_white: [BRAND_COLORS.lavender, BACKGROUND_PURPLE],
  lavender_on_medium_purple: [BRAND_COLORS.lavender, BRAND_COLORS.mediumPurple],
};

export const LANGUAGES = {
  english: 'English',
  haitian: 'Kreyòl Ayisyen',
  chinese: '中文',
  spanish: 'Español',
  vietnamese: 'Tiếng Việt',
  capeverdean: 'Cape Verdean Creole',
};

export const SIZES = {
  twitter: {
    name: 'Twitter Post',
    className: 'twitter',
    width: 1200,
    height: 675,
  },
  ig: {
    name: 'Instagram Post',
    className: 'ig',
    width: 1080,
    height: 1080,
  },
  story: {
    name: 'Instagram Story',
    className: 'story',
    width: 1080,
    height: 1920,
  },
  sign: {
    name: 'Small Sign',
    className: 'sign',
    width: 3300,
    height: 2550,
  },
};
