import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledRadioButton = styled.div`
  &:not(:last-child) {
    margin-bottom: 5px;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `};

  input[type='radio'] {
    position: absolute;
    opacity: 0;

    &:checked,
    &:hover,
    &:focus {
      + .radio__label {
        &:before {
          background-color: ${({ theme }) => theme.colors.mediumPurple};
        }
      }
    }

    &:checked:focus {
      + .radio__label {
        outline: -webkit-focus-ring-color auto 1px;
        outline-offset: 2px;
      }
    }
  }

  .radio__label {
    display: flex;
    align-items: center;
    cursor: pointer;

    ${props =>
    props.disabled &&
      css`
        &:hover,
        &:focus {
          cursor: not-allowed;
        }
      `};

    &:before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 10px;
      border: 1px solid ${({ theme }) => theme.colors.grey};
      border-radius: 50%;
      box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.white};
      cursor: pointer;
      transition: ${({ theme }) => theme.transition};
    }
  }
`;

const RadioButton = ({ id, label, name, onChangeHandler, isChecked, disabled }) => (
  <StyledRadioButton disabled={disabled}>
    <input
      id={id}
      name={name}
      type="radio"
      onChange={onChangeHandler}
      checked={isChecked}
      disabled={disabled}
    />
    <label htmlFor={id} className="radio__label">
      {label}
    </label>
  </StyledRadioButton>
);

RadioButton.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChangeHandler: PropTypes.func,
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default RadioButton;
