import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const StyledTypeDrawer = styled.div`
  .insta {
    padding-left: 15%;
  }
`;

const TypographyDrawer = () => {
  const data = useStaticQuery(graphql`
    query {
      posters: file(relativePath: { eq: "typography/drawer/posters.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sign: file(relativePath: { eq: "typography/drawer/supportersign.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      insta: file(relativePath: { eq: "typography/drawer/insta.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledTypeDrawer>
      <div className="drawer__grid">
        <div className="drawer__grid-item drawer__grid-item--full-width">
          <div className="drawer__grid-item-lockup">
            <div>
              <Img fluid={data.posters.childImageSharp.fluid} />
            </div>
            <p>
              <b>Example poster application.</b> Simple color fields and left-aligned all caps
              headline treatments keep the layouts focused on supporter portraits.
            </p>
          </div>
        </div>

        <div className="drawer__grid-item">
          <div className="drawer__grid-item-lockup">
            <div>
              <Img fluid={data.sign.childImageSharp.fluid} />
            </div>
            <p>
              <b>Example supporter sign application.</b> Layering painted typographic elements in
              analogous colors adds depth to applications like signage.
            </p>
          </div>
        </div>

        <div className="drawer__grid-item insta">
          <div className="drawer__grid-item-lockup">
            <div>
              <Img fluid={data.insta.childImageSharp.fluid} />
            </div>
            <p>
              <b>Example instagram slides.</b> In these example Instagram slides, sentence case is
              used, and the type sizes are limited to two levels. Textured and collage elements add
              visual interest.
            </p>
          </div>
        </div>
      </div>
    </StyledTypeDrawer>
  );
};

export default TypographyDrawer;
