import React from 'react';
import theme from '../theme';

/* eslint react/prop-types: 0 */

const Close = ({
  width = '2opx',
  height = '20px',
  strokeColor = 'lavender',
  strokeWidth = '4',
}) => (
  <svg height={height} width={width} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="2.41421"
      y1="1.58579"
      x2="18.4142"
      y2="17.5858"
      stroke={theme.colors[strokeColor]}
      strokeWidth={strokeWidth}
    />
    <line
      x1="2.25376"
      y1="17.5858"
      x2="18.2538"
      y2="1.58579"
      stroke={theme.colors[strokeColor]}
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default Close;
