import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PlusIcon from '@images/icons/plus.svg';
import MinusIcon from '@images/icons/minus.svg';

const StyledDrawer = styled.div`
  display: none;

  @media (${({ theme }) => theme.bp.tabletL}) {
    display: block;
    margin-top: 50px;
  }

  .drawer__button {
    ${({ theme }) => theme.mixins.allCapsText(theme.fz.sm)};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 20px;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.mediumPurple};
    box-shadow: inset 0 -1px 0 ${({ theme }) => theme.colors.mediumPurple};

    &:hover,
    &:focus {
      box-shadow: inset 0 -4px 0 ${({ theme }) => theme.colors.mediumPurple};
      outline: 0;
    }

    .icon {
      ${({ theme }) => theme.mixins.flexCenter()};
      margin-left: 20px;
      margin-top: 2px;
    }
  }

  .drawer__content {
    height: 0;
    overflow: hidden;
    visibility: hidden;

    &[aria-hidden='false'] {
      height: auto;
      overflow: auto;
      visibility: visible;
      padding-top: 50px;
    }
  }

  .drawer__grid {
    @media (${({ theme }) => theme.bp.tabletL}) {
      ${({ theme }) => theme.mixins.twoColumns()};
      margin-bottom: 30px;
    }
  }

  .drawer__grid-item {
    margin-bottom: 20px;

    &--full-width {
      grid-column: 1 / -1;

      p {
        max-width: 50%;
      }
    }

    p {
      ${({ theme }) => theme.mixins.smallPurpleText()};
      margin: 20px 10% 0 0;
    }
  }

  .drawer__grid-item-lockup {
    display: grid;
    grid-template-rows: 2fr 1fr;
    height: 100%;

    @media (${({ theme }) => theme.bp.desktopS}) {
      grid-template-rows: 3fr 1fr;
    }

    @media (${({ theme }) => theme.bp.desktopM}) {
      grid-template-rows: 4fr 1fr;
    }
  }

  .drawer__grid-item-image {
    ${({ theme }) => theme.mixins.flexCenter()};
    position: relative;
    padding-top: 52%; /* For aspect ratio */
    border: 1px solid ${({ theme }) => theme.colors.darkPurple};

    > div {
      ${({ theme }) => theme.mixins.flexCenter()};
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 25%;
    }
  }
`;

const Drawer = ({ children, drawerId, contentId }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (scrollToTop = false) => {
    if (scrollToTop) {
      document.getElementById(drawerId).scrollIntoView();
    }

    setDrawerOpen(!drawerOpen);
  };

  const DrawerButton = ({ scrollToTop }) => (
    <button
      onClick={() => toggleDrawer(scrollToTop)}
      id={drawerId}
      className="drawer__button"
      aria-controls={contentId}
      aria-expanded={drawerOpen}>
      <span>{drawerOpen ? 'Close' : 'More Details'}</span>
      <span className="icon">{drawerOpen ? <MinusIcon /> : <PlusIcon />}</span>
    </button>
  );

  DrawerButton.propTypes = {
    scrollToTop: PropTypes.bool,
  };

  return (
    <StyledDrawer>
      <DrawerButton />

      <div
        id={contentId}
        className="drawer__content"
        role="region"
        aria-labelledby={drawerId}
        aria-hidden={!drawerOpen}>
        {children}
      </div>

      {drawerOpen ? <DrawerButton scrollToTop={true} /> : null}
    </StyledDrawer>
  );
};

Drawer.propTypes = {
  children: PropTypes.element,
  drawerId: PropTypes.string,
  contentId: PropTypes.string,
};

export default Drawer;
