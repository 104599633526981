import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Drawer, LogosDrawer } from '@components';

const StyledLogos = styled.section`
  .logos__top {
    @media (${({ theme }) => theme.bp.tabletL}) {
      ${({ theme }) => theme.mixins.twoColumns('40px')};
    }
  }

  .logos__lockups {
    margin-top: 60px;
    padding: 0 40px;

    @media (${({ theme }) => theme.bp.tabletL}) {
      ${({ theme }) => theme.mixins.flexBetween()};
      margin-top: 0;
      padding: 0;
      min-width: 50%;
    }

    .lockup {
      width: 100%;
      max-width: 300px;
      margin: 20px auto 0;

      &.one {
        @media (${({ theme }) => theme.bp.tabletL}) {
          margin-top: 0;
        }
        @media (${({ theme }) => theme.bp.desktopS}) {
          margin-top: -10%;
        }
      }
      &.two {
        @media (${({ theme }) => theme.bp.tabletL}) {
          margin-top: 40%;
        }
        @media (${({ theme }) => theme.bp.desktopS}) {
          margin-top: 20%;
        }
      }
    }
  }
`;

const Logos = () => {
  const data = useStaticQuery(graphql`
    query {
      logoLavender: file(relativePath: { eq: "logos/logo-lavender.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoMint: file(relativePath: { eq: "logos/logo-mint.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledLogos id="logos">
      <div className="logos__top">
        <div className="logos__text">
          <h2>Campaign Logos</h2>
          <p>
            The painted element background layer of Michelle’s logo is inspired by the urgency of
            handmade signs at public demonstrations and marches. Our primary logo color scheme includes
            include a lavender and mint version in either English or Chinese. There are many other
            alternates including horizontal versions, supporting group logos, icons, and language variations.
          </p>
          <a
            href="https://drive.google.com/drive/folders/12qhJzY1aMhh4QphfROvhqYK71gs7aIDy?usp=sharing"
            className="download-link">
            Download Logo Suite
          </a>
        </div>

        <div className="logos__lockups">
          <div className="lockup one">
            <Img fluid={data.logoLavender.childImageSharp.fluid} />
          </div>
          <div className="lockup two">
            <Img fluid={data.logoMint.childImageSharp.fluid} />
          </div>
        </div>
      </div>

      <Drawer drawerId="logos-drawer" contentId="logos-content">
        <LogosDrawer />
      </Drawer>
    </StyledLogos>
  );
};

export default Logos;
