import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const StyledImagery = styled.section`
  @media (${({ theme }) => theme.bp.tabletL}) {
    ${({ theme }) => theme.mixins.flexBetween()};
  }

  .imagery__text {
    margin-bottom: 50px;

    @media (${({ theme }) => theme.bp.tabletL}) {
      order: 2;
    }
  }

  .imagery__images {
    @media (${({ theme }) => theme.bp.tabletL}) {
      min-width: 50%;
      order: 1;
      padding-right: 50px;
    }

    @media (${({ theme }) => theme.bp.tabletL}) {
      min-width: 60%;
    }

    .image-grid {
      ${({ theme }) => theme.mixins.twoColumns()};
    }

    .imagery__attribution {
      ${({ theme }) => theme.mixins.smallPurpleText()};
      margin-top: 30px;
    }
  }
`;

const Imagery = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "imagery/dove.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "imagery/love.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "imagery/earth.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "imagery/hope.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledImagery id="imagery">
      <div className="imagery__text">
        <h2>Imagery</h2>
        <p>
          We've created an image library inspired by the messages and graphics of handmade signs. Use
          these to make stickers, pins, or your own collages &mdash; we can't wait to see what you
          make!
        </p>
        <a
          href="https://drive.google.com/drive/folders/1IewKDhyMP0ZH12uyCeV-loISEceBe0CP?usp=sharing"
          className="download-link">
          Download Imagery Kit
        </a>
      </div>

      <div className="imagery__images">
        <div className="image-grid">
          <div className="image-grid__item one">
            <Img fluid={data.image1.childImageSharp.fluid} />
          </div>
          <div className="image-grid__item two">
            <Img fluid={data.image2.childImageSharp.fluid} />
          </div>
          <div className="image-grid__item three">
            <Img fluid={data.image3.childImageSharp.fluid} />
          </div>
          <div className="image-grid__item four">
            <Img fluid={data.image4.childImageSharp.fluid} />
          </div>
        </div>

        <p className="imagery__attribution">
          Handmade signs imagery is derived from the archival project{' '}
          <a href="http://artofthemarch.boston/container/archive">Art of the March</a>, used under{' '}
          <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>.
        </p>
      </div>
    </StyledImagery>
  );
};

export default Imagery;
