import React from 'react';
import theme from '../theme';

const Chevron = ({
  width = '24px',
  height = '24px',
  strokeColor = 'purple',
  strokeWidth = '0.1',
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height}>
    <g fill={theme.colors[strokeColor]}>
      <path
        strokeWidth={strokeWidth}
        fill={theme.colors[strokeColor]}
        d="M12 16.41l-7-7L6.41 8 12 13.59 17.59 8 19 9.41z"
      />
    </g>
  </svg>
);

export default Chevron;
