import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RotatingHeadline from './RotatingHeadline';
import TOC from './TOC';

const StyledHeader = styled.header`
  .header__nav {
    ${({ theme }) => theme.mixins.flexBetween()};

    .logo {
      max-width: 140px;

      @media (${({ theme }) => theme.bp.tabletL}) {
        max-width: 200px;
      }
    }
  }

  .header__inner {
    margin-top: 10px;

    @media (${({ theme }) => theme.bp.mobileL}) {
      margin-top: 10px;
    }

    .header__title {
      margin-right: 10%;
      max-width: 900px;
      color: ${({ theme }) => theme.colors.mediumPurple};
      font-size: 40px;
      line-height: 1;

      @media (${({ theme }) => theme.bp.mobileL}) {
        font-size: 50px;
      }

      @media (${({ theme }) => theme.bp.tabletL}) {
        margin-right: 20%;
        font-size: 60px;
      }

      @media (${({ theme }) => theme.bp.desktopS}) {
        font-size: 80px;
      }

      .keyword {
        color: ${({ theme }) => theme.colors.coral};
      }
    }

    .header__description {
      @media (${({ theme }) => theme.bp.tabletL}) {
        max-width: 770px;
      }
    }
  }

  .header__toc {
    margin-top: 50px;

    @media (${({ theme }) => theme.bp.desktopS}) {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const Header = ({ siteTitle }) => (
  <StyledHeader>

    <div className="header__inner">
      <RotatingHeadline />
      <p className="header__description">
        Whether you're looking for a <a href="#graphics-generator">graphic to show support</a> on social
        media, a campaign-themed <a href="#pattern">Zoom background</a>, or the story behind our{' '}
        <a href="#typography">typeface</a>, you've come to the right place. This campaign is of and
        by grassroots supporters, and we can't wait to see what you make!
      </p>
    </div>

    <div className="header__toc">
      <TOC />
    </div>
  </StyledHeader>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
