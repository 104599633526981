/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Header, Footer, Navigation } from '@components';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from '@styles';

const StyledSiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;

  main {
    flex-grow: 1;
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // Sets target="_blank" rel="noopener noreferrer" on external links
  const handleExternalLinks = () => {
    const allLinks = Array.from(document.querySelectorAll('a'));
    if (allLinks.length > 0) {
      allLinks.forEach(link => {
        if (link.host !== window.location.host) {
          link.setAttribute('rel', 'noopener noreferrer');
          link.setAttribute('target', '_blank');
        }
      });
    }
  };

  useEffect(() => {
    handleExternalLinks();
  }, []);

  const navRef = useRef();
  const alertRef = useRef();
  const [isSticky, setSticky] = useState(false);
  const [navHeight, setNavHeight] = useState(50);

  const handleScroll = () => {
    if (navRef && navRef.current) {
      const elRect = navRef.current.getBoundingClientRect();
      let newNavHeight = elRect.height;
      // eslint-disable-next-line
      window.pageYOffset > elRect.bottom
        ? setSticky(true)
        : setSticky(false);

      if (alertRef && alertRef.current) {
        const alRect = navRef.current.getBoundingClientRect().height;
        newNavHeight += alRect;
      }
      setNavHeight(newNavHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <StyledSiteWrapper>
        <GlobalStyle />
        <a className="skip-link" href="#main-content">
          Skip to main content
        </a>
        <Navigation
          navRef={navRef}
          isSticky={isSticky}
          navHeight={navHeight}
          navTheme="light"
          mobNavTheme="light"
        />

        <Header siteTitle={data.site.siteMetadata.title} />

        <main id="main-content">{children}</main>

        <Footer />
      </StyledSiteWrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
