import React from 'react';
import styled from 'styled-components';
import { LOGO_PALETTES, LANGUAGES, SIZES } from '@utils';
import { useLogoState } from '@hooks';
import { ColorPairButton, RadioButton, LogoDownload } from '@components';

const StyledConfigs = styled.div`
  color: ${({ theme }) => theme.colors.darkPurple};
  margin-top: 30px;

  @media (${({ theme }) => theme.bp.tabletL}) {
    margin-top: 0;
  }

  /* Language and application configs */
  .config__meta {
    ${({ theme }) => theme.mixins.twoColumns('10px')};

    @media (${({ theme }) => theme.bp.tabletL}) {
      display: block;
    }
  }

  .config {
    margin-bottom: 30px;

    @media (${({ theme }) => theme.bp.tabletL}) {
      margin-bottom: 40px;
    }

    .config__title {
      ${({ theme }) => theme.mixins.allCapsText()};
      margin: 0 0 20px;

      @media (${({ theme }) => theme.bp.tabletL}) {
        ${({ theme }) => theme.mixins.allCapsText(theme.fz.sm)};
      }
    }

    &.colors {
      ul {
        display: flex;
        margin: 0 -5px;

        @media (${({ theme }) => theme.bp.tabletL}) {
          flex-wrap: wrap;
        }
      }

      li {
        margin: 0 5px 5px 0;
      }
    }

    &.sizes {
      ul {
        margin: 0 -5px;

        @media (${({ theme }) => theme.bp.tabletS}) {
          ${({ theme }) => theme.mixins.twoColumns('10px 20px')};
        }
      }
    }
  }
`;

const LogoConfigs = () => {
  const { state, dispatch } = useLogoState();
  const { selectedLogo, logoPalette, language, exportSize } = state;
  const selectedLogoPath = `/logos/${selectedLogo}/${logoPalette}/${exportSize}/${language}.png`;

  return (
    <StyledConfigs>
      <div>
        <div className="config colors">
          <p className="config__title">Choose A Color Combination</p>

          <ul>
            {LOGO_PALETTES
              ? Object.entries(LOGO_PALETTES).map(([id, colorPair]) => (
                <li key={id}>
                  <ColorPairButton
                    colorPair={colorPair}
                    clickHandler={() => dispatch({ type: 'logoPalette', logoPalette: id })}
                    active={logoPalette === id}
                    disabled={
                      (language === 'capeverdean' && id === 'coral_on_white') ||
                      (language === 'vietnamese' && id === 'coral_on_white') ||
                      (language === 'capeverdean' && id === 'lavender_on_medium_purple') ||
                      (language === 'capeverdean' && id === 'mint_on_white') ||
                      (language === 'vietnamese' && id === 'lavender_on_medium_purple') ||
                      (language === 'vietnamese' && id === 'mint_on_white')
                    }
                  />
                </li>
              ))
              : null}
          </ul>
        </div>
      </div>

      <div className="config__meta">
        <div className="config languages">
          <p className="config__title">Select A Language</p>

          <fieldset id="languages">
            {LANGUAGES
              ? Object.entries(LANGUAGES).map(([id, label]) => (
                <RadioButton
                  key={id}
                  id={id}
                  label={label}
                  name="languages"
                  onChangeHandler={() => dispatch({ type: 'language', language: id })}
                  isChecked={language === id}
                  disabled={
                    (selectedLogo === 'chinese' && (
                      id === 'haitian' ||
                      id === 'spanish' ||
                      id === 'capeverdean' ||
                      id === 'vietnamese'
                    )) ||
                    (selectedLogo === 'pattern' && (
                      exportSize === 'twitter' ||
                      exportSize === 'ig'
                    )) ||
                    (selectedLogo === 'horizontal' && (
                      id === 'vietnamese' ||
                      id === 'capeverdean'
                    )) ||
                    (selectedLogo === 'pattern' && (
                      id === 'vietnamese' ||
                      id === 'capeverdean'
                    )) ||
                    (
                      (
                        logoPalette === 'coral_on_white' ||
                        logoPalette === 'lavender_on_medium_purple' ||
                        logoPalette === 'mint_on_white'
                      ) &&
                      (
                        id === 'capeverdean' ||
                        id === 'vietnamese'
                      )
                    )
                  }
                />
              ))
              : null}
          </fieldset>
        </div>

        <div className="config sizes">
          <p className="config__title">Select An Application</p>

          <fieldset id="sizes">
            {SIZES
              ? Object.entries(SIZES).map(([id, size]) => (
                <RadioButton
                  key={id}
                  id={id}
                  label={size.name}
                  name="sizes"
                  onChangeHandler={() => dispatch({ type: 'exportSize', size: id })}
                  isChecked={exportSize === id}
                />
              ))
              : null}
          </fieldset>
        </div>
      </div>

      <LogoDownload selectedLogoPath={selectedLogoPath} />
    </StyledConfigs>
  );
};

export default LogoConfigs;
