import { useEffect, useState } from 'react';

const useCheckExternalLink = href => {
  const [isExternal, setIsExternal] = useState(false);

  useEffect(() => {
    if (href && href.includes(window && window.location.hostname)) {
      setIsExternal(false);
    } if (href && href.startsWith('/' || '#')) {
      setIsExternal(false);
    }
    setIsExternal(true);
  }, [href]);

  return isExternal;
};

export default useCheckExternalLink;
