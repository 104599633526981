import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { LOGO_PALETTES, LOGOS, LANGUAGES, SIZES } from '@utils';

const LogoStateContext = createContext();
const initialState = {
  selectedLogo: Object.keys(LOGOS)[0],
  logoPalette: Object.keys(LOGO_PALETTES)[0],
  language: Object.keys(LANGUAGES)[0],
  exportSize: Object.keys(SIZES)[0],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'selectedLogo':
      return {
        ...state,
        selectedLogo: action.logo,
        action: action.type,
      };
    case 'logoPalette':
      return {
        ...state,
        logoPalette: action.logoPalette,
        action: action.type,
      };
    case 'language':
      return {
        ...state,
        language: action.language,
        action: action.type,
      };
    case 'exportSize':
      return {
        ...state,
        exportSize: action.size,
        action: action.type,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const LogoStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LogoStateContext.Provider value={{ state, dispatch }}>{children}</LogoStateContext.Provider>
  );
};

LogoStateProvider.propTypes = {
  children: PropTypes.element,
};

export const useLogoState = () => useContext(LogoStateContext);
