import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Arrow from '@images/icons/arrow.svg';
import { useLogoState } from '@hooks';

const StyledDownloadSection = styled.div`
  display: flex;

  .download-button {
    ${({ theme }) => theme.mixins.allCapsText()};
    display: flex;
    align-items: center;
    padding: 14px 25px 17px;
    border-radius: 30px;
    background-color: ${({ theme }) => theme.colors.coral};

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.mint};
      color: ${({ theme }) => theme.colors.darkPurple};
    }

    .icon {
      width: 10px;
      margin-left: 10px;
    }
  }
`;

const LogoDownload = ({ selectedLogoPath }) => {
  const { state } = useLogoState();
  const { selectedLogo, logoPalette, exportSize } = state;
  const filename = `logo-${selectedLogo}-${exportSize}-${logoPalette}.png`;

  return (
    <StyledDownloadSection>
      <a href={selectedLogoPath} download={filename} className="plain-link download-button">
        <span>Download</span>
        <span className="icon">
          <Arrow />
        </span>
      </a>
    </StyledDownloadSection>
  );
};

LogoDownload.propTypes = {
  selectedLogoPath: PropTypes.string,
};

export default LogoDownload;
