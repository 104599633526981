import React from 'react';
import styled from 'styled-components';

const StyledPattern = styled.section`
  ${({ theme }) => theme.mixins.flexCenter()};
  margin-top: 20px;
  max-width: initial;
  min-height: 600px;
  background-image: url('/pattern/pattern-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: ${({ theme }) => theme.colors.darkPurple};
  color: ${({ theme }) => theme.colors.white};

  @media (${({ theme }) => theme.bp.mobileL}) {
    margin-top: 40px;
    min-height: 800px;
  }

  @media (${({ theme }) => theme.bp.desktopS}) {
    margin-top: 80px;
  }

  .pattern__inner {
    padding: 0 20px 40px;

    @media (${({ theme }) => theme.bp.tabletL}) {
      max-width: 540px;
      padding: 40px;
      margin-left: -15%;
    }
  }

  h2 {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Pattern = () => (
  <StyledPattern id="pattern">
    <div className="pattern__inner">
      <h2>Pattern</h2>
      <p>
        Download this resizable pattern swatch to easily show your support on everything from tote
        bags to Zoom backgrounds to social banners.
      </p>
      <a
        href="https://drive.google.com/drive/folders/1bctacH13KSbuja0TLjrDWqAz3Bx_jNkl?usp=sharing"
        className="download-link on-dark">
        Download Swatch
      </a>
    </div>
  </StyledPattern>
);

export default Pattern;
