import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const StyledAbout = styled.section`
  margin-top: 40px;

  .about__inner {
    position: relative;
    padding: 30px 20px;
    background-color: ${({ theme }) => theme.colors.mediumPurple};
    color: ${({ theme }) => theme.colors.white};

    @media (${({ theme }) => theme.bp.tabletL}) {
      display: flex;
      justify-content: flex-end;
      padding: 60px 40px;
    }

    @media (${({ theme }) => theme.bp.desktopM}) {
      margin-left: 5%;
      padding: 100px;
    }
  }

  .about__img {
    margin: -30% auto 30px;
    max-width: 500px;

    @media (${({ theme }) => theme.bp.mobileL}) {
      margin: -20% auto 20px;
    }

    @media (${({ theme }) => theme.bp.tabletL}) {
      flex-grow: 1;
      position: absolute;
      top: -10%;
      left: -5%;
      width: 50%;
      margin: 0;
      max-width: initial;
    }

    @media (${({ theme }) => theme.bp.desktopM}) {
      left: -13%;
      width: 60%;
    }

    @media (${({ theme }) => theme.bp.desktopL}) {
      top: -20%;
    }
  }

  .about__text {
    width: 100%;

    @media (${({ theme }) => theme.bp.tabletL}) {
      width: 55%;
    }

    @media (${({ theme }) => theme.bp.desktopM}) {
      width: 50%;
    }

    h2 {
      color: ${({ theme }) => theme.colors.white};
      line-height: 1;
    }

    a {
      box-shadow: inset 0 -1px 0 ${({ theme }) => theme.colors.white};

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.mint};
        box-shadow: inset 0 -1px 0 ${({ theme }) => theme.colors.mint};
      }
    }
  }
`;

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutImage: file(relativePath: { eq: "about/collage.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledAbout id="about">
      <div className="about__inner">
        <div className="about__img">
          <Img fluid={data.aboutImage.childImageSharp.fluid} />
        </div>

        <div className="about__text">
          <h2>A Boston For Everyone</h2>
          <p>
            Michelle Wu is a different kind of candidate. She's a voice for accessibility,
            transparency, and community engagement in city leadership. Read more about her policies{' '}
            <a href="http://www.michelleforboston.com/">here</a>.
          </p>
        </div>
      </div>
    </StyledAbout>
  );
};

export default About;
