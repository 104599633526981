import styled, { css, keyframes } from 'styled-components';
import { rgba } from 'polished';
import createBreakpoint from '../../styles/create-breakpoint';
import switchBasedOnScheme from '../../utils/switchBasedOnScheme';

const LIGHT = 'light';
const DARK = 'dark';

const slideDown = keyframes`
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
`;

const swipeRight = keyframes`
  0% {
    transform: translateX(-80vw);
  }
  100% {
    transform: translateX(0px);
  }
`;

const swipeLeft = keyframes`
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-80vw);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translate3d(-50px,-100px,0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
`;

const slideLeft = keyframes`
  0% {
    transform: translate3d(0px,50px,0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
`;

export const NavigationWrapper = styled.nav`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.darkPurple};
  z-index: ${({ theme, mobOpen }) => (mobOpen ? theme.zIndices.mobileNav : theme.zIndices.navSubItems)};

  ${({ mobNavTheme, theme, isSticky }) => switchBasedOnScheme(mobNavTheme, {
    [LIGHT]: css`
          background-color: ${theme.colors.white};
        `,
    [DARK]: css`
          background-color: ${isSticky ? theme.colors.white : theme.colors.darkPurple};
        `,
  })}

  ${({ isSticky, theme, mobOpen }) => (isSticky ? css`
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.2rem 0.2rem;
    animation: ${slideDown} 0.3s ease-in-out;
    z-index: ${mobOpen ? theme.zIndices.mobileNav : theme.zIndices.stickyNav};
    padding: 12px 0px 8px 0px;
  ` : css`
    padding: 15px 0px;
    position: relative;
    min-height: 123px;
  `)}


  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
  ${({ navTheme, isSticky }) => switchBasedOnScheme(navTheme, {
    [LIGHT]: css`
          background-color: ${theme.colors.white};
        `,
    [DARK]: css`
          background-color: ${isSticky ? theme.colors.white : theme.colors.darkPurple};
        `,
  })}
  `)}
`;

export const ModalBackground = styled.div`
  ${({ mobOpen, theme }) => (mobOpen && css`
      position: fixed;
      overflow: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      min-width: 100vw;
      min-height: 100vh;
      height: 100%;
      background: ${rgba(theme.colors.mediumPurple, 0.8)};
      z-index: ${theme.zIndices.mobileNav};
      animation-name: ${fadeIn};
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in;
  `)}

  ${({ mobClosing, theme }) => (mobClosing && css`
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    background: ${rgba(theme.colors.mediumPurple, 0.8)};
    z-index: ${theme.zIndices.mobileNav};
    animation-name: ${fadeOut};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
  `)}

  ${({ mobOpen, mobClosing }) => (!mobOpen && !mobClosing && css`
      display: none;
  `)}
`;

export const ContentWrapper = styled.div`
  ${({ mobOpen, mobClosing }) => ((!mobOpen && !mobClosing) && css`
      position: relative;
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px 16px;
  `)}

  ${({ mobOpen, theme }) => (mobOpen && css`
      padding: ${theme.layout.gutter}px;
      position: fixed;
      overflow-y: auto;
      top: 0;
      left: 0;
      width: 80vw;
      max-width: 80vw;
      min-height: 100vh;
      height: 100%;
      background: ${theme.colors.darkPurple};
      z-index: ${theme.zIndices.mobileNav};
      display: flex;
      flex-direction: column;
      animation-name: ${swipeRight};
      animation-duration: 0.7s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in;
  `)}

  ${({ mobClosing }) => mobClosing && css`
    animation-name: ${swipeLeft};
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
  `}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    transition: background 0.3s ease-in-out;
    align-items: stretch;
    justify-content: center;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0px ${theme.layout.gutter}px;
    flex: 1 1 auto;
    margin: auto;
    max-width: 1304px;
  `)}
`;

export const ListWrappers = styled.div`
  position: relative;
  display: flex;
  margin: 0px;
  order: 2;
  min-height: ${({ isSticky }) => (isSticky ? '64px' : '94px')};
  flex: 0 0 auto;

  ${({ mobOpen }) => (mobOpen ? css`
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  ` : css`
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex: 1 1 100%;
  `)};

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    justify-content: center;
    align-items: center;
    flex: 1 1 100%;
    min-height: ${({ isSticky }) => (isSticky ? '67px' : '94px')};
  `)}
`;

export const ItemsList = styled.ul`
  list-style: none;
  margin-block-end: 0px;
  margin-block-start: 0px;
  padding-inline-start: 0px;
  ${({ mobOpen, theme }) => (mobOpen ? css`
    display: flex;
    flex-direction: column;
    align-items: start;
    color: ${theme.colors.white};
    margin-top: 50px;
  ` : css`
    display: none;
  `)};
  order: 0;

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    font-size: 1em;
    line-height: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    min-height: 100%;
    justify-content: flex-end;
    z-index: 1;
    flex: 1 1 auto;

    ${({ navTheme, isSticky }) => switchBasedOnScheme(navTheme, {
    [LIGHT]: css`
      color: ${theme.colors.white};
    `,
    [DARK]: css`
      color: ${isSticky ? theme.colors.white : theme.colors.darkPurple};
    `,
  })}
  `)}
`;

export const LanguageItemsList = styled(ItemsList)`
  width: fit-content;
  order: 1;
  margin-top: 0px;

  ${({ mobOpen }) => (mobOpen ? css`
    display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
    flex-direction: column;
    align-items: start;
    justify-content: center;
    order: 4;
    margin-top: 45px;
  ` : css`
    display: none;
  `)};

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    order: 0;
    width: fit-content;
    align-self: flex-end;
    margin-bottom: -10px;
    margin-right: calc(${theme.button.radius} / 4);
    z-index: 1;
    display: ${({ mobile }) => (mobile ? 'none' : 'flex')};
  `)}
`;

export const ActionItemsList = styled(ItemsList)`
  display: flex;
  width: fit-content;
  z-index: 1;
  order: 1;
  margin-top: 0px;

  ${({ mobOpen }) => (mobOpen ? css`
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 100px;
  ` : css`
    flex-direction: row;
  `)};


  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    order: 0;
    width: fit-content;
    justify-content: flex-end;
  `)}
`;

export const HomepageItemsList = styled(ItemsList)`
  display: flex;
  align-self: center;
  justify-content: flex-start;
  width: fit-content;
  z-index: 1;

  ${({ mobOpen }) => (mobOpen ? css`
    align-self: flex-start;
    justify-content: flex-start;
    order: 1;
    margin-top: 0px;
    margin-bottom: 60px;
  ` : css`
    position: absolute;
    left: calc(50% - 56.5px);
  `)};

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    ${({ mobOpen }) => (mobOpen ? css`
      align-self: flex-start;
      justify-content: flex-start;
    ` : css`
      position: absolute;
      left: calc(50% - 68.7px);
    `)};
  `)}


  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    order: 0;
    width: fit-content;
    position: relative;
    left: 0px;
  `)}
`;

export const ItemButton = styled.button`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  line-height: 1.65;
  padding: 0px;
  display: flex;
  width: 100%;
  text-align: left;
  background-color: transparent;

  > svg {
    transition: transform 0.3s ease-in-out;
    width: fit-content;
    vertical-align: baseline;

    g, path {
      fill: ${({ theme }) => theme.colors.white};
    }

    transform: ${({ expanded }) => (expanded ? 'rotate(-180deg)' : 'rotate(0deg)')}
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    text-align: center;
    padding: 3px;
    width: fit-content;
    > svg g, > svg g path {
      fill: ${theme.colors.white}
    }

    &:hover > svg,  &:focus > svg {
      transform: rotate(-180deg);
    }
  `)}

  > span:first-child {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

export const Item = styled.li`
  display: none;
  ${({ theme, navTheme, isSticky }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    font-size: 16px;
    text-transform: uppercase;
    margin-right: 25px;
    font-weight: 700;
    line-height: 1;
    display: flex;
    justify-content: center;
    padding: 20px 5px 0px 5px;
    position: relative;

    a {
      box-shadow: none;
      &:hover, &:focus {
        box-shadow: none;
      }
    }

    & > a, & > span {
      color: inherit;
      cursor: pointer;
      position: relative;
      flex: 1 1 100%;
      align-self: center;
      letter-spacing: 0.16em;
      background-image: linear-gradient(transparent, transparent);
      background-position: 0% 100%;
      background-repeat: no-repeat;
      background-size: 100% 0em;
      transition: background-size .3s, background-position .3s, padding 0.3s, margin 0.3s;
      padding-bottom: 0.75em;
      margin-bottom: -0.75em;
    }

    &:hover, &:focus {
      & > a, & > span {
        background-size: 100% 0.25em;
        background-position: 0% 100%;
        padding-bottom: 0.75em;
        margin-bottom: -0.75em;
        background-image: linear-gradient(${theme.colors.coral}, ${theme.colors.coral});
      }

      & > ${ItemButton} > svg {
        transform: rotate(-180deg);

        path {
          stroke-width: 4px;
        }
      }
    }

    ${switchBasedOnScheme(navTheme, {
    [LIGHT]: css`
      color: ${theme.colors.darkPurple};
      & > a, & > span {
        &:after {
          background: ${theme.colors.coral};
        }
      }
      & > ${ItemButton} > svg {
        path {
          fill: ${theme.colors.darkPurple};
        }
      }
    `,
    [DARK]: css`
      color: ${isSticky ? theme.colors.darkPurple : theme.colors.white};
      & > a, & > span {
        &:after {
          background: ${theme.colors.coral};
        }
      }
      & > ${ItemButton} > svg {
        path {
          fill: ${isSticky ? theme.colors.darkPurple : theme.colors.white};
        }
      }
     `,
  })}
  `)}
`;

export const MobileItem = styled.li`
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${({ mobOpen }) => (mobOpen ? '45px' : '25px')};
  animation: ${({ mobOpen }) => (mobOpen && slideIn)};
  animation-duration: 0.65s;
  animation-timing-function: ease;

  a {
    box-shadow: none;
    &:hover, &:focus {
      box-shadow: none;
    }
  }

  & > a, & > span, & > ${ItemButton}  {
    color: inherit;
    cursor: pointer;
    position: relative;
    flex: 1 1 100%;
    align-self: center;
    align-items: center;
    letter-spacing: 0.16em;
    font-size: inherit;
    font-weight: inherit;
    text-transform: inherit;
    background-image: linear-gradient(transparent, transparent);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 0em;
    transition: background-size .3s, background-position .3s, padding 0.3s, margin 0.3s;
    padding-bottom: 0.25em;
    margin-bottom: -0.25em;
  }

  &:hover, &:focus {
    & > a, & > span {
      background-size: 100% 0.25em;
      background-position: 0% 100%;
      padding-bottom: 0.25em;
      margin-bottom: -0.25em;
      background-image: linear-gradient(${({ theme }) => theme.colors.coral}, ${({ theme }) => theme.colors.coral});
    }

    & > ${ItemButton} {
      background-size: 100% 0.25em;
      background-position: 0% 100%;
      padding-bottom: 0.25em;
      margin-bottom: -0.25em;
      background-image: linear-gradient(${({ theme }) => theme.colors.coral}, ${({ theme }) => theme.colors.coral});
      & > svg {
        transform: rotate(-180deg);

        path {
          stroke-width: 4px;
        }
      }
    }
  }

  ${({ expanded }) => expanded && css`
    & > a:after, & > span:after {
      opacity: 1;
      transform: translateY(3px);
    }

    & > ${ItemButton} > svg {
      transform: rotate(-180deg);

      path {
        stroke-width: 1;
      }
    }
  `}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    display: none;
  `)}
`;

export const HomepageItem = styled.li`
  font-size: 1em;
  line-height: 1;
  padding: 5px;
  margin: 0;
  display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  padding: 0px;

  a {
    flex: 2 1 100%;
    padding: 0px;
    box-shadow: none;
    &:hover, &:focus {
      box-shadow: none;
    }
  }

  svg {
    transition: filter 0.3s;
    width: 113px;
    height: 71px;
    vertical-align: baseline;
  }

  ${({ mobOpen, theme }) => (mobOpen ? css`
    span {
      display: block;
      color: ${theme.colors.white};
      font-weight: 700;
      letter-spacing: 0.18em;
      text-transform: uppercase;
      font-size: 22px;
    }
    svg {
      display: none;
    }
  ` : css`
    span {
      display: none;
    }
  `)};

  &:hover svg {
    filter: drop-shadow(10px 5px 5px rgba(0, 0, 0, 0.05));
  }

  ${({ isSticky }) => (isSticky && css`
    svg {
      width: 113px;
      height: 71px;
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    svg {
      width: 137.4px;
      height: 86px;
    }
    ${({ isSticky }) => (isSticky && css`
      a svg {
        width: 113px;
        height: 71px;
      }
    `)}
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    display: ${({ mobile }) => (!mobile ? 'flex' : 'none')};
    a {
      padding: 5px 5px 0px 0px;
    }
    svg {
      width: 189px;
      height: 118px;
    }
    ${({ isSticky }) => (isSticky && css`
      a svg {
        width: 161px;
        height: 100px;
      }
    `)}
  `)}
`;

export const LanguageItem = styled.li`
  font-size: ${({ mobOpen }) => (mobOpen ? '18px' : '14px')};
  margin-bottom: ${({ mobOpen }) => (mobOpen ? '22px' : '0px')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 100%;
  animation: ${({ mobOpen }) => mobOpen && slideIn};
  animation-duration: 0.65s;
  animation-timing-function: ease;

  a {
    display: block;
    white-space: nowrap;
    font-weight: 700;
    &:hover, &:focus {
      box-shadow: none;
    }
  }

  ${({
    mobNavTheme, theme, mobOpen, isSticky,
  }) => switchBasedOnScheme(mobNavTheme, {
    [LIGHT]: css`
      a {
        color: ${mobOpen ? theme.colors.white : theme.colors.darkPurple};
      }
      &:hover, &:focus {
        a {
          color: ${mobOpen ? theme.colors.mint : theme.colors.secondary};
        }
      }
    `,
    [DARK]: css`
      a {
        color: ${isSticky ? theme.colors.darkPurple : theme.colors.white};
      }
      &:hover, &:focus {
        a {
          color: ${isSticky ? theme.colors.secondary : theme.colors.mint};
        }
      }
    `,
  })}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding: 0px;
    margin-left: 25px;
    ${({
    navTheme, mobOpen, isSticky,
  }) => switchBasedOnScheme(navTheme, {
    [LIGHT]: css`
        a {
          color: ${mobOpen ? theme.colors.white : theme.colors.darkPurple};
        }
        &:hover, &:focus {
          a {
            color: ${mobOpen ? theme.colors.mint : theme.colors.secondary};
          }
        }
      `,
    [DARK]: css`
        a {
          color: ${isSticky ? theme.colors.darkPurple : theme.colors.white};
        }
        &:hover, &:focus {
          a {
            color: ${isSticky ? theme.colors.secondary : theme.colors.mint};
          }
        }
      `,
  })}
  `)}
`;

export const SubitemsList = styled.ul`
  line-height: 1.65;
  list-style: none;
  margin-block-end: 0px;
  margin-block-start: 0px;
  padding-inline-start: 0px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 0px 0px 20px;
  display: none;
  transition: visibility .3s ease-in-out linear .3s, opacity 0.35s;
  opacity: 0;
  list-style-type: none;

  ${({ expanded }) => expanded && css`
    display: flex;
    opacity: 1;
  `}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    color: ${theme.colors.darkPurple};
    border-top: 0.25em solid ${theme.colors.coral};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    position: absolute;
    left: 5px;
    top: 100%;
    padding: 0px;
    min-width: 200px;
    visibility: hidden;
    transition: visibility 0.3s ease-in-out linear .3s, opacity 0.35s;
    transform: translateY(-5px);
    z-index: -1;
    border-bottom: 1px solid ${theme.colors.lightGrey};
    opacity: 0;

    ${({ expanded }) => expanded && css`
      transform: translateY(1px);
      visibility: visible;
      opacity: 1;
      transition: transform 0.3s ease-in-out, opacity 0.35s;
    `}

  ${({ navTheme }) => switchBasedOnScheme(navTheme, {
    [LIGHT]: css`
        background-color: ${theme.colors.lightCoral};
        `,
    [DARK]: css`
        background-color: ${theme.colors.lightCoral};
        `,
  })}
  `)}
`;

export const Subitem = styled.li`
  font-size: 20px;
  margin: 0px 0px 20px;
  animation: ${slideLeft};
  animation-duration: 0.65s;
  animation-timing-function: ease;
  animation-delay: ${({ index }) => `${index * 0.05}s`};

  &:last-child {
    margin-bottom: 0px;
  }

  & > a {
    color: ${({ theme }) => theme.colors.white};
    position: relative;
    flex: 1 1 100%;
    letter-spacing: 0em;
    line-height: 1.2;
    text-transform: none;
  }

  a {
    box-shadow: none;
    &:hover, &:focus {
      box-shadow: none;
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: 100%;
    margin: 0px;
    border-top: 1px solid ${theme.colors.white};
    transition: background-color 0.3s;

    & > a {
      color: ${theme.colors.darkPurple};
      font-size: 18px;
      text-transform: none;
      padding: 10px 20px;
      display: block;
    }

    &:first-of-type {
      border-top-width: 0px;
      & > a {
        padding-top: 6px;
      }
    }

    &:hover, &:focus {
      background-color: ${theme.colors.coral}
    }
  `)}
`;

export const PrimaryActionItem = styled.li`
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: ${({ mobOpen }) => (mobOpen ? '100%' : 'fit-content')};
  padding: 0px;

  span {
    padding: 0px;
  }

  a {
    cursor: pointer;
    transition: color 0.3s, background 0.3s;
    font-size: ${({ mobOpen }) => (mobOpen ? '22px' : '14px')};
    letter-spacing: ${({ mobOpen }) => (mobOpen ? '0.16em' : '0.08em')};
    border-radius: ${({ theme }) => theme.button.radius};
    line-height: 1;
    padding: ${({ mobOpen }) => (mobOpen ? '6px 15px 8px 15px' : '5px 9.5px 7px 9.5px')};
    width: ${({ mobOpen }) => (mobOpen ? '100%' : 'fit-content')};
  }

  a {
    box-shadow: none;
    &:hover, &:focus {
      box-shadow: none;
    }
  }

  ${({
    mobNavTheme, theme, mobOpen, isSticky,
  }) => switchBasedOnScheme(mobNavTheme, {
    [LIGHT]: css`
      a {
        color: ${mobOpen ? theme.colors.darkPurple : theme.colors.white};
        background-color: ${mobOpen ? theme.colors.coral : theme.colors.mediumPurple};

        &:hover, &:focus{
          color: ${theme.colors.darkPurple};
          background-color: ${theme.colors.mint};
        }
      }
      `,
    [DARK]: css`
        a {
          color: ${isSticky ? theme.colors.white : theme.colors.darkPurple};
          background-color: ${isSticky ? theme.colors.mediumPurple : theme.colors.coral};

          &:hover, &:focus {
            color: ${theme.colors.darkPurple};
            background-color: ${theme.colors.mint};
          }
        }
      `,
  })}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding: 20px 5px 0px 5px;
    animation: none;

    a {
      height: fit-content;
      font-size: 16px;
      padding: 7px 24px 8px 24px;
    }

    ${({
    navTheme, mobOpen, isSticky,
  }) => switchBasedOnScheme(navTheme, {
    [LIGHT]: css`
        a {
          color: ${mobOpen ? theme.colors.darkPurple : theme.colors.white};
          background-color: ${mobOpen ? theme.colors.coral : theme.colors.mediumPurple};

          &:hover, &:focus{
            color: ${theme.colors.darkPurple};
            background-color: ${theme.colors.mint};
          }
        }
        `,
    [DARK]: css`
          a {
            color: ${isSticky ? theme.colors.white : theme.colors.darkPurple};
            background-color: ${isSticky ? theme.colors.mediumPurple : theme.colors.coral};

            &:hover, &:focus {
              color: ${theme.colors.darkPurple};
              background-color: ${theme.colors.mint};
            }
          }
        `,
  })}
  `)}
`;

export const SecondaryActionItem = styled.li`
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: ${({ mobOpen }) => (mobOpen ? '100%' : 'fit-content')};
  padding-top: ${({ mobOpen }) => (mobOpen ? '0px' : '20px')};

  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    text-transform: uppercase;
    font-weight: 700;
    justify-content: center;
    font-size: ${({ mobOpen }) => (mobOpen ? '22px' : '16px')};
    letter-spacing: 0.16em;
    border-radius: ${({ theme }) => theme.button.radius};
    line-height: 1.2;
    padding: 3px 15px 6px 15px;
    width: ${({ mobOpen }) => (mobOpen ? '100%' : 'fit-content')};
  }

  a {
    box-shadow: none;
    &:hover, &:focus {
      box-shadow: none;
    }
  }

  ${({ mobOpen, theme }) => (mobOpen ? css`
    a {
      color: ${theme.colors.darkPurple};
      background-color: ${theme.colors.lightPrimary};
      margin-bottom: 22px;

      &:hover, &:focus {
        color: ${theme.colors.white};
        background-color: ${theme.colors.mediumPurple};
      }
    }
  ` : css`
    display: none;
  `)};

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding: 20px 5px 0px 5px;
    animation: none;
    display: flex;

    a {
      height: fit-content;
    }

    ${({ navTheme, isSticky }) => switchBasedOnScheme(navTheme, {
    [LIGHT]: css`
      a {
        width: 100%;
        color: ${theme.colors.darkPurple};
        background-color: ${theme.colors.medPrimary};

        &:hover, &:focus {
          color: ${theme.colors.darkPurple};
          background-color: ${theme.colors.lightPrimary};
        }
      }
      `,
    [DARK]: css`
        a {
          width: 100%;
          color: ${isSticky ? theme.colors.darkPurple : theme.colors.white};
          background-color: ${isSticky ? theme.colors.medPrimary : theme.colors.mediumPurple};

          &:hover, &:focus {
            color: ${isSticky ? theme.colors.darkPurple : theme.colors.darkPurple};
            background-color: ${isSticky ? theme.colors.lightPrimary : theme.colors.medPrimary};
          }
        }
      `,
  })}
  `)}
`;

export const MenuButton = styled.button`
  color: ${({ theme }) => theme.colors.mediumPurple};
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-weight: 700;
  letter-spacing: 0.08em;
  padding: 0px;
  transition: color: 0.3s;
  background-color: transparent;

  svg {
    width: fit-content;
    vertical-align: baseline;
    polygon {
      transition: fill 0.3s;
    }
  }

  ${({
    mobOpen, theme, mobNavTheme, isSticky,
  }) => (mobOpen ? css`
    color: ${theme.colors.white};
    justify-content: flex-start;
    &:hover {
      color ${theme.colors.mint};
      svg {
        polygon, line {
          fill: ${theme.colors.mint};
        }
      }
    }
    svg {
      width: 10px;
      margin-right: 8px;
      top: 0px;
      position: relative;
      polygon, line {
        fill: ${theme.colors.white};
      }
    }
  ` : css`
    svg {
      top: 1px;
      position: relative;
    }
    ${switchBasedOnScheme(mobNavTheme, {
      [LIGHT]: css`
      color: ${theme.colors.mediumPurple};
      svg {
        margin-left: 3px;
        polygon {
          fill: ${theme.colors.mediumPurple};
        }
      }
      `,
      [DARK]: css`
      color: ${isSticky ? theme.colors.mediumPurple : theme.colors.white};
      svg {
        margin-left: 8px;
        polygon {
          fill: ${isSticky ? theme.colors.mediumPurple : theme.colors.white};
        }
      }
      &:hover {
        color ${isSticky ? theme.colors.secondary : theme.colors.mint};
        svg {
          polygon, line {
            fill: ${isSticky ? theme.colors.secondary : theme.colors.mint};
          }
        }
      }
      `,
    })}
  `)};

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    display: none;
  `)}
`;
