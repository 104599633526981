import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const StyledPhotography = styled.section`
  .photo__examples {
    margin-bottom: 40px;

    @media (${({ theme }) => theme.bp.mobileL}) {
      margin-bottom: 80px;
    }

    .gatsby-image-wrapper {
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  .photo__intro {
    @media (${({ theme }) => theme.bp.tabletL}) {
      ${({ theme }) => theme.mixins.oneColumnText()};
    }
  }

  .photo__headshots {
    margin-top: 60px;
  }

  .photo__headshots-images {
    ${({ theme }) => theme.mixins.flexBetween()};
    align-items: flex-end;
    margin: 50px 0;

    .img {
      width: 50%;

      &.two {
        padding-left: 20px;
      }
    }
  }

  .photo__headshots-description {
    @media (${({ theme }) => theme.bp.tabletL}) {
      ${({ theme }) => theme.mixins.oneColumnText()};
    }
  }

  .photo__headshots-email {
    ${({ theme }) => theme.mixins.smallPurpleText()};
  }
`;

const Photography = () => {
  const data = useStaticQuery(graphql`
    query {
      portraits: file(relativePath: { eq: "photography/portraits.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      headshot1: file(relativePath: { eq: "photography/headshot1.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      headshot2: file(relativePath: { eq: "photography/headshot2.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledPhotography id="photography">
      <div className="photo__examples">
        <Img fluid={data.portraits.childImageSharp.fluid} />
      </div>

      <h2>Photography</h2>

      <div className="photo__intro">
        <p>
          We love including your stories in our campaign toolkit! Portraits are shot in natural
          light with rich color and minimal effects. The images feel particular to Boston, and we
          show hints of neighborhoods and landmarks in the background to celebrate the diversity of
          our beloved city.
        </p>
      </div>

      <div className="photo__headshots">
        <div className="photo__headshots-images">
          <div className="img one">
            <Img fluid={data.headshot1.childImageSharp.fluid} />
          </div>
          <div className="img two">
            <Img fluid={data.headshot2.childImageSharp.fluid} />
          </div>
        </div>

        <div className="photo__headshots-description">
          <p>
            There are two different ways you can treat headshot photography to support the campaign.
            First, place Michelle’s silhouette in front of a painted Wu! backdrop, and second, use a
            sketchy outline to separate her silhouette from the background. By using these
            treatments, we create a recognizable look for the campaign.
          </p>
        </div>

        <p className="photo__headshots-email">
          Please <a href="mailto:info@michelleforboston.com">email us</a> if you're interested in
          using Michelle's headshots.
        </p>
      </div>
    </StyledPhotography>
  );
};

export default Photography;
