import { createGlobalStyle } from 'styled-components';
import Fonts from './fonts';

const GlobalStyle = createGlobalStyle`
  ${Fonts};

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;

    @media (prefers-reduced-motion: no-preference) {
      scroll-behavior: smooth; /* not supported by safari */
    }
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    overflow-x: hidden;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.darkGrey};
    font-family: ${({ theme }) => theme.font};
    font-size: ${({ theme }) => theme.fz.sm};
    line-height: 1.4;
    font-feature-settings: "ss01", "dlig";
  }

  header,
  footer,
  section {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px 20px;

    @media (${({ theme }) => theme.bp.mobileL}) {
      padding: 40px;
    }
    @media (${({ theme }) => theme.bp.desktopS}) {
      padding: 60px 80px;
    }
  }

  header {
    padding: 20px 20px 0 20px;

    @media (${({ theme }) => theme.bp.mobileL}) {
      padding: 40px 40px 0 40px;
    }
    @media (${({ theme }) => theme.bp.desktopS}) {
      padding: 60px 80px 0 80px;
    }
  }

  footer {
    padding-bottom: 40px;

    @media (${({ theme }) => theme.bp.desktopS}) {
      padding-bottom: 100px;
    }
  }

  main {
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 30px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.darkPurple};
  }

  h1 {
    font-size: 80px;
  }

  h2 {
    font-size: 32px;

    @media (${({ theme }) => theme.bp.mobileL}) {
      font-size: 50px;
    }
  }

  h3 {
    font-size: 40px;
  }

  p {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: ${({ theme }) => theme.fz.md};

    @media (${({ theme }) => theme.bp.mobileL}) {
      margin-bottom: 30px;
      font-size: ${({ theme }) => theme.fz.lg};
    }
  }

  a,
  button {
    cursor: pointer;
    transition: ${({ theme }) => theme.transition};
  }

  a {
    color: inherit;
    text-decoration: none;
    box-shadow: inset 0 -5px 0 ${({ theme }) => theme.colors.lightMint};

    &:hover,
    &:focus {
      box-shadow: inset 0 -7px 0 ${({ theme }) => theme.colors.lightMint};
    }

    &.plain-link {
      box-shadow: none;

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.mediumPurple};
      }
    }

    &.download-link {
      ${({ theme }) => theme.mixins.allCapsText()};
      line-height: 2;
      padding: 8px 0;
      box-shadow: inset 0 -4px 0 ${({ theme }) => theme.colors.coral};

      &:hover,
      &:focus {
        box-shadow: inset 0 -6px 0 ${({ theme }) => theme.colors.coral};
      }

      &.on-dark {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  button {
    padding: 10px;
    border: 0;
    color: inherit;
    font-size: 100%;
    font-family: ${({ theme }) => theme.font};
    cursor: pointer;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  img,
  svg {
    vertical-align: middle;
    max-width: 100%;
    width: 100%;
  }

  svg {
    fill: currentColor;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  .visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .skip-link {
    position: absolute;
    left: -999px;
    width:1px;
    height: 1px;
    top: auto;

    &:focus {
      color: ${({ theme }) => theme.colors.purple};
      display: inline-block;
      height: auto;
      width: auto;
      position: static;
      margin: auto;
    }
  }

  .hairline-border {
    border: 1px solid ${({ theme }) => theme.colors.hairline} !important;
  }

  .mobile-only {
    display: block;

    @media (${({ theme }) => theme.bp.tabletL}) {
      display: none;
    }
  }

  .desktop-only {
    display: none;

    @media (${({ theme }) => theme.bp.tabletL}) {
      display: block;
    }
  }
`;

export default GlobalStyle;
