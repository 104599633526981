import React, { useState, useEffect } from 'react';
import AnimateHeight from 'react-animate-height';
import { useStaticQuery, graphql } from 'gatsby';
import FocusTrap from 'focus-trap-react';
import Link from './link';
import LinkButton from './linkButton';
import {
  Subitem, Item, ItemsList, SubitemsList, ItemButton, NavigationWrapper, ListWrappers,
  LanguageItem, PrimaryActionItem, SecondaryActionItem, ActionItemsList, MobileItem,
  HomepageItemsList, LanguageItemsList, HomepageItem, ContentWrapper, MenuButton,
  ModalBackground,
} from './navigation.styles';
import Logo from '../../styles/icons/logo';
import Arrow from '../../styles/icons/arrow';
import Close from '../../styles/icons/close';
import Chevron from '../../styles/icons/chevron';
import slugify from '../../utils/slugify';

/* eslint react/prop-types: 0 */

const getFinalPath = string => {
  if (string && string.startsWith('/')) {
    return `https://www.michelleforboston.com${string}`;
  } else {
    return string;
  }
};

const NavItem = props => {
  const [expanded, setExpanded] = useState(false);
  const {
    path, title, subitems, navTheme, isSticky, mobNavTheme,
  } = props;
  const expandSubNav = () => {
    if (!expanded) {
      setExpanded(true);
    }
  };
  const collapseSubNav = () => {
    if (expanded) {
      setExpanded(false);
    }
  };
  const controlSubNav = e => {
    if (e.key === 'Enter') {
      setExpanded(!expanded);
    }
  };
  return (
    <Item
      onMouseOver={expandSubNav}
      onMouseLeave={collapseSubNav}
      navTheme={navTheme}
      mobNavTheme={mobNavTheme}
      isSticky={isSticky}
    >
      {path ? <Link href={getFinalPath(path)}>{title}</Link> : <span>{title}</span>}
      {subitems && (
        <React.Fragment>
          <ItemButton
            onKeyPress={e => controlSubNav(e)}
            aria-expanded={expanded}
            aria-controls={`subnav--${slugify(title)}`}
            navTheme={navTheme}
            mobNavTheme={mobNavTheme}
          >
            <span>show submenu</span>
            <Chevron />
          </ItemButton>
          <SubitemsList
            id={`subnav--${slugify(title)}`}
            expanded={expanded}
            role="menu"
            navTheme={navTheme}
            mobNavTheme={mobNavTheme}
          >
            {subitems.map(subitem => (
              <Subitem key={`sub--${subitem.path}`} role="menuitem">
                <Link href={getFinalPath(subitem.path)}>{subitem.title}</Link>
              </Subitem>
            ))}
          </SubitemsList>
        </React.Fragment>
      )}
    </Item>
  );
};

const MobileNavItem = props => {
  const [expanded, setExpanded] = useState(false);
  const {
    path, title, subitems, mobOpen,
  } = props;
  const controlSubNav = e => {
    if (e.key === 'Enter') {
      setExpanded(!expanded);
    }
  };
  return (
    <MobileItem mobOpen={mobOpen}>
      {subitems ? (
        <React.Fragment>
          <ItemButton
            aria-expanded={expanded}
            aria-controls={`subnav--${slugify(title)}`}
            onKeyPress={e => controlSubNav(e)}
            onClick={() => setExpanded(!expanded)}
            expanded={expanded}
          >
            <span>show submenu</span>
            <span>{title}</span>
            <Chevron strokeColor="white" />
          </ItemButton>
          <AnimateHeight
            duration={subitems.length * 150}
            height={expanded ? 'auto' : 0}
          >
            <SubitemsList id={`mobsubnav--${slugify(title)}`} expanded={expanded} role="menu">
              {subitems.map((subitem, index) => (
                <Subitem index={index} key={`${subitem.title}--${subitem.path}`} role="menuitem">
                  <Link href={getFinalPath(subitem.path)}>{subitem.title}</Link>
                </Subitem>
              ))}
              {path && (
                <Subitem index={subitems.length}>
                  <Link href={getFinalPath(path)}>{title}</Link>
                </Subitem>
              )}
            </SubitemsList>
          </AnimateHeight>
        </React.Fragment>
      ) : (
        <Link href={getFinalPath(path)}>{title}</Link>
      )}
    </MobileItem>
  );
};

const Navigation = ({
  languages, navRef, isSticky, navHeight, navTheme, mobNavTheme,
}) => {
  const data = useStaticQuery(graphql`
    query {
      global: contentfulGlobalSettings(language: {eq: "enUS"}) {
        navigation {
          items {
            title
            path
            subitems {
              title
              path
            }
          }
          homepage {
            title
            path
          }
          primaryAction {
            title
            path
          }
          secondaryAction {
            title
            path
          }
          languages {
            code
            display
          }
        }
      }
    }
  `);
  const [mobOpen, setMobOpen] = useState(false);
  const [mobClosing, setMobClosing] = useState(false);
  const {
    items, homepage, primaryAction, secondaryAction,
  } = data.global.navigation;

  useEffect(() => {
    const page = document.getElementsByTagName('html');
    if (page && page[0]) {
      if (mobOpen) {
        page[0].classList.add('overlay-active');
      } else {
        page[0].classList.remove('overlay-active');
      }
    }
  }, [mobOpen]);

  return (
    <FocusTrap active={mobOpen}>
      <NavigationWrapper
        id="navigation"
        role="navigation"
        ref={navRef}
        isSticky={isSticky}
        navHeight={navHeight}
        navTheme={navTheme}
        mobNavTheme={mobNavTheme}
        mobOpen={mobOpen}
      >
        <ModalBackground mobOpen={mobOpen} mobClosing={mobClosing} />
        <ContentWrapper
          mobOpen={mobOpen}
          mobClosing={mobClosing}
          isSticky={isSticky}
        >
          <ListWrappers mobOpen={mobOpen} isSticky={isSticky}>
            <MenuButton
              onClick={() => {
                if (mobOpen) {
                  setMobClosing(true);
                  setTimeout(() => {
                    setMobOpen(!mobOpen);
                    setMobClosing(false);
                  }, 650);
                } else {
                  setMobOpen(!mobOpen);
                }
              }}
              mobOpen={mobOpen}
              aria-expanded={mobOpen}
              navTheme={navTheme}
              mobNavTheme={mobNavTheme}
              isSticky={isSticky}
            >
              {mobOpen && <Close width="10px" height="10px" strokeColor="white" />}
              {mobOpen ? 'close' : 'menu'}
              {!mobOpen && <Arrow strokeColor="white" height="10px" width="10px" />}
            </MenuButton>
            {homepage && (
              <HomepageItemsList mobOpen={mobOpen} role="menu">
                <HomepageItem mobOpen={mobOpen} role="menuitem" isSticky={isSticky} mobile>
                  <Link title={homepage.title} href={getFinalPath(homepage.path)}>
                    <span>{homepage.title}</span>
                    <Logo
                      accentFill={((mobOpen || mobNavTheme === 'dark') && !isSticky) ? 'mediumPurple' : 'mint'}
                      textFill={((mobOpen || mobNavTheme === 'dark') && !isSticky) ? 'white' : 'darkPurple'}
                    />
                  </Link>
                </HomepageItem>
                <HomepageItem mobOpen={mobOpen} role="menuitem" isSticky={isSticky}>
                  <Link title={homepage.title} href={getFinalPath(homepage.path)}>
                    <span>{homepage.title}</span>
                    <Logo
                      accentFill={((mobOpen || navTheme === 'dark') && !isSticky) ? 'mediumPurple' : 'mint'}
                      textFill={((mobOpen || navTheme === 'dark') && !isSticky) ? 'white' : 'darkPurple'}
                    />
                  </Link>
                </HomepageItem>
              </HomepageItemsList>
            )}
            {items && (
              <ItemsList mobOpen={mobOpen} role="menu">
                {items.map(item => (
                  <NavItem
                    {...item}
                    role="menuitem"
                    navTheme={navTheme}
                    mobNavTheme={mobNavTheme}
                    isSticky={isSticky}
                    key={`${item.title}--desktop`}
                  />
                ))}
                {items.map(item => (
                  <MobileNavItem
                    {...item}
                    mobOpen={mobOpen}
                    role="menuitem"
                    isSticky={isSticky}
                    key={`${item.title}--mobile`}
                  />
                ))}
              </ItemsList>
            )}
            <ActionItemsList mobOpen={mobOpen} role="menu">
              {secondaryAction && (
                <SecondaryActionItem
                  isSticky={isSticky}
                  mobOpen={mobOpen}
                  role="menuitem"
                  navTheme={navTheme}
                  mobNavTheme={mobNavTheme}
                >
                  <Link href={getFinalPath(secondaryAction.path)}>
                    {secondaryAction.title}
                  </Link>
                </SecondaryActionItem>
              )}
              {primaryAction && (
                <PrimaryActionItem
                  isSticky={isSticky}
                  mobOpen={mobOpen}
                  role="menuitem"
                  navTheme={navTheme}
                  mobNavTheme={mobNavTheme}
                >
                  <LinkButton
                    href={getFinalPath(primaryAction.path)}
                    textTheme="purple"
                    buttonTheme="coral"
                  >
                    {primaryAction.title}
                  </LinkButton>
                </PrimaryActionItem>
              )}
            </ActionItemsList>
            {items && (
              <LanguageItemsList
                key="mobile-lang"
                mobile
                mobOpen={mobOpen}
                role="menu"
                navTheme={navTheme}
                mobNavTheme={mobNavTheme}
              >
                {languages && languages.map(language => {
                  const forDisplay = data.global.navigation.languages.find(lang => (
                    lang.code === language.language
                  ));
                  return (
                    <LanguageItem
                      role="menuitem"
                      navTheme={navTheme}
                      mobNavTheme={mobNavTheme}
                      mobOpen={mobOpen}
                      isSticky={isSticky}
                      key={`${forDisplay.display}--desktop`}
                    >
                      <Link href={getFinalPath(language.pageUrl)}>{forDisplay.display}</Link>
                    </LanguageItem>
                  );
                })}
              </LanguageItemsList>
            )}
          </ListWrappers>
          {items && (
            <LanguageItemsList
              key="desktop-lang"
              mobile={false}
              mobOpen={mobOpen}
              role="menu"
              navTheme={navTheme}
              mobNavTheme={mobNavTheme}
            >
              {languages && languages.map(language => {
                const forDisplay = data.global.navigation.languages.find(lang => (
                  lang.code === language.language
                ));
                return (
                  <LanguageItem
                    role="menuitem"
                    navTheme={navTheme}
                    mobNavTheme={mobNavTheme}
                    mobOpen={mobOpen}
                    key={`${forDisplay.display}--mobile`}
                  >
                    <Link href={language.pageUrl}>{forDisplay.display}</Link>
                  </LanguageItem>
                );
              })}
            </LanguageItemsList>
          )}
        </ContentWrapper>
      </NavigationWrapper>
    </FocusTrap>
  );
};

export default Navigation;
