import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import TexturesText from '@images/textures/textures-text.svg';

const StyledTextures = styled.section`
  padding-top: 0;

  @media (${({ theme }) => theme.bp.tabletL}) {
    ${({ theme }) => theme.mixins.flexBetween()};
    align-items: center;
    padding: 0 0 60px 0;
  }

  .textures__left {
    max-width: 400px;
    margin: 0 auto;
    padding-left: 5%;

    @media (${({ theme }) => theme.bp.tabletL}) {
      max-width: initial;
      min-width: 40%;
    }
  }

  .textures__right {
    text-align: center;

    @media (${({ theme }) => theme.bp.tabletL}) {
      flex-grow: 1;
    }

    .textures__text {
      max-width: 600px;
      margin: 0 auto;
    }

    .textures__email {
      ${({ theme }) => theme.mixins.smallPurpleText()};
    }
  }
`;

const Textures = () => {
  const data = useStaticQuery(graphql`
    query {
      tote: file(relativePath: { eq: "textures/wu-tote.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StyledTextures id="textures">
      <div className="textures__left">
        <Img fluid={data.tote.childImageSharp.fluid} />
      </div>

      <div className="textures__right">
        <div className="textures__text">
          <div>
            <TexturesText />
            <h2 className="visually-hidden">
              Textured elements emphasize copy and set the tone with a grassroots authenticity
            </h2>
          </div>

          <p className="textures__email">
            <a href="mailto:info@michelleforboston.com">Email us</a> to inquire about using our full
            set of painted textures.
          </p>
        </div>
      </div>
    </StyledTextures>
  );
};

export default Textures;
