import React from 'react';
import styled from 'styled-components';
import WuSvg from '@images/logos/wu.svg';

const StyledWuBanner = styled.section`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  padding: 0 20px;

  @media (${({ theme }) => theme.bp.mobileL}) {
    padding: 20px 40px;
  }

  @media (${({ theme }) => theme.bp.desktopS}) {
    padding: 20px 80px;
  }

  svg {
    &:nth-of-type(n) {
      fill: ${({ theme }) => theme.colors.mint};
    }
    &:nth-of-type(2n) {
      fill: ${({ theme }) => theme.colors.mediumPurple};
    }
    &:nth-of-type(3n) {
      fill: ${({ theme }) => theme.colors.coral};
    }
  }
`;

const WuBanner = () => (
  <StyledWuBanner>
    {[...Array(9)].map((e, i) => (
      <WuSvg key={i} />
    ))}
  </StyledWuBanner>
);

export default WuBanner;
