import styled, { css } from 'styled-components';
import createBreakpoint from '../../styles/create-breakpoint';
import switchBasedOnScheme from '../../utils/switchBasedOnScheme';

const SMALL = 'small';
const MEDIUM = 'medium';
const LARGE = 'large';
const XLARGE = 'xlarge';

const LinkButton = styled.span`
  display: flex;
  align-items: center;
  white-space: normal;
  width: 100%;

  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    text-transform: uppercase;
    font-weight: 700;
    justify-content: center;
    font-size: 16px;
    letter-spacing: 0.16em;
    border-radius: ${({ theme }) => theme.button.radius};
    line-height: 1.2;
    width: 100%;
    text-align: center;
    box-shadow: none;
    color: ${({ theme, textTheme }) => theme.colors[textTheme] || theme.colors.white};
    background-color: ${({ theme, buttonTheme }) => theme.colors[buttonTheme] || theme.colors.coral};
    &:hover {
      box-shadow: none;
      background-color: ${({ theme, buttonHoverTheme }) => theme.colors[buttonHoverTheme] || theme.colors.coral};
      color: ${({ theme, textHoverTheme }) => theme.colors[textHoverTheme] || theme.colors.white};
    }
  }

  ${({ size }) => switchBasedOnScheme(size, {
    [SMALL]: css`
      a {
        font-size: 14px;
        letter-spacing: 0.08em;
        padding: 4px 5px 6px 5px;
      }
    `,
    [MEDIUM]: css`
      a {
        font-size: 14px;
        padding: 5px 15px 7px 15px;
        letter-spacing: 0.16em;
      }
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        a {
          font-size: 16px;
          padding: 6.5px 24px 8.5px 24px;
        }
      `)}
    `,
    [LARGE]: css`
      a {
        font-size: 16px;
        padding: 8px 25px 9px 25px;
      }
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        a {
          font-size: 18px;
          padding: 11px 45px 12px 45px;
        }
    `)}
    `,
    [XLARGE]: css`
      a {
        font-size: 18px;
        padding: 14px 30px 15px 30px;
      }
      ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
        a {
          font-size: 20px;
          padding: 13px 45px 16px 45px;
        }
    `)}
    `,
  })}
`;

export default LinkButton;
