import React from 'react';
import styled from 'styled-components';
import { LOGOS, LOGO_PALETTES, SIZES, WHITE, BACKGROUND_PURPLE } from '@utils';
import { useLogoState } from '@hooks';
import { LogoConfigs } from '@components';

const StyledLogoPicker = styled.section`
  .description {
    margin-bottom: 50px;

    @media (${({ theme }) => theme.bp.tabletL}) {
      ${({ theme }) => theme.mixins.oneColumnText()};
    }

    h2 {
      max-width: 400px;
    }
  }

  .container {
    @media (${({ theme }) => theme.bp.tabletL}) {
      display: grid;
      grid-template-columns: 8fr 2fr;
      grid-gap: 40px;
    }

    .configs {
      min-width: 185px; /* Make sure colors don't wrap to 2 columns */
    }
  }

  ul {
    ${({ theme }) => theme.mixins.listStyleNone()};
  }

  .disclaimer {
    ${({ theme }) => theme.mixins.smallPurpleText()};
    margin: 20px 0 0;

    @media (${({ theme }) => theme.bp.tabletL}) {
      ${({ theme }) => theme.mixins.oneColumnText()};
    }
  }
`;

const StyledPickerView = styled.div`
  ul.logo-choices {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    margin-bottom: 5px;

    @media (${({ theme }) => theme.bp.tabletL}) {
      grid-gap: 10px;
      margin-bottom: 10px;
    }
  }

  li.logo-choice {
    display: flex;

    button {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;

        &:hover,
        &:focus {
          &:before {
            border-color: transparent;
          }
        }
      }

      /* Make disabled state more obvious with light backgrounds */
      &.hairline-border:disabled {
        opacity: 0.3;
      }

      &:before {
        content: '';
        display: block;
        border: 5px solid transparent;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: ${({ theme }) => theme.transition};
      }

      &:hover,
      &:focus {
        outline: 0;

        &:before {
          border-color: ${({ theme }) => theme.colors.mint};
        }
      }

      &.active {
        border-color: ${({ theme }) => theme.colors.coral} !important;

        &:before {
          border-color: ${({ theme }) => theme.colors.coral};
        }
      }
    }
  }

  .logo-selected {
    position: relative;
    background-color: #eeeeee;
  }

  .logo-selected-container {
    margin: 0 auto;

    &.story {
      max-width: 375px;
    }

    .logo-selected-ratio {
      position: relative;

      .logo-selected-inner {
        ${({ theme }) => theme.mixins.flexCenter('column')};
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const Logos = () => {
  const { state, dispatch } = useLogoState();
  const { selectedLogo, logoPalette, language, exportSize } = state;
  const backgroundColor = LOGO_PALETTES[logoPalette][1].hex;
  const hairlineBorder =
    backgroundColor === WHITE.hex || backgroundColor === BACKGROUND_PURPLE.hex
      ? 'hairline-border'
      : '';

  // Default to english asset for pattern logo haitian IG story
  const displayedLanguage =
    selectedLogo === 'pattern' && exportSize === 'story' && language === 'haitian'
      ? 'english'
      : language;

  const selectedLogoPath = `/logos/${selectedLogo}/${logoPalette}/${exportSize}/${displayedLanguage}.png`;

  const Disclaimer = () => (
    <p className="disclaimer">
      By downloading any of these designs, you agree to only use them for your personal,
      noncommercial use to show support for the Michelle Wu for Boston campaign.
    </p>
  );

  return (
    <StyledLogoPicker id="graphics-generator">
      <div className="description">
        <h2>Create Your Own Designs to Share</h2>
        <p>
          Generate graphics in three simple steps with our tool below to show your support for
          Michelle. Download them to print or share on social media to spread the word about our
          campaign! Show us what you make by tagging{' '}
          <a href="https://www.instagram.com/explore/tags/wutrain/">#WuTrain</a>
        </p>
      </div>

      <div className="container">
        <div>
          <StyledPickerView>
            <ul className="logo-choices">
              {LOGOS
                ? Object.entries(LOGOS).map(([logoId, logo]) => {
                  const thumbnailPath = `/logos/${logo.path}/${logoPalette}/thumbnail.png`;

                  return (
                    <li key={logoId} className="logo-choice">
                      <button
                        onClick={() => dispatch({ type: 'selectedLogo', logo: logoId })}
                        style={{ backgroundColor }}
                        className={`${selectedLogo === logoId ? 'active' : ''} ${hairlineBorder}`}
                        // disable chinese logo when language is haitian or spanish
                        disabled={
                          (logoId === 'chinese' &&
                            (language === 'haitian' || language === 'spanish' || language === 'vietnamese' || language === 'capeverdean')) ||
                          (logoId === 'horizontal' &&
                            (language === 'vietnamese' || language === 'capeverdean')) ||
                          (logoId === 'pattern' &&
                            (language === 'vietnamese' || language === 'capeverdean'))
                        }>
                        <img src={thumbnailPath} alt={logo.name} />
                      </button>
                    </li>
                  );
                })
                : null}
            </ul>

            <div className={`logo-selected ${hairlineBorder}`}>
              <div className={`logo-selected-container ${SIZES[exportSize].className}`}>
                <div
                  className="logo-selected-ratio"
                  style={{
                    paddingTop: `${(SIZES[exportSize].height / SIZES[exportSize].width) * 100}%`,
                  }}>
                  <div className="logo-selected-inner" style={{ backgroundColor }}>
                    <img src={selectedLogoPath} alt="Selected logo" />
                  </div>
                </div>
              </div>
            </div>
          </StyledPickerView>

          <div className="desktop-only">
            <Disclaimer />
          </div>
        </div>

        <div className="configs">
          <LogoConfigs />
        </div>
      </div>

      <div className="mobile-only">
        <Disclaimer />
      </div>
    </StyledLogoPicker>
  );
};

export default Logos;
